import {
  deleteProjectTypeDetail,
  postProjectTypeDetail,
  putProjectTypeDetail,
} from '@apis/projectApi';
import Empty from '@components/Empty/Empty';
import {
  ActionIcon,
  Anchor,
  Button,
  createStyles,
  Divider,
  Group,
  Input,
  Modal,
  NumberInput,
  Paper,
  ScrollArea,
  Switch,
  Table,
  Text,
  TextInput,
  TypographyStylesProvider,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useElementSize, useViewportSize } from '@mantine/hooks';
import { openConfirmModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import RichTextEditor from '@mantine/rte';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hook';
import { getProjectTypesApi } from 'redux/reducer/projectType.slice';

const useStyles = createStyles((theme) => ({
  header: {
    position: 'sticky',
    top: 0,
    zIndex: 10,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    transition: 'box-shadow 150ms ease',

    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2]
      }`,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },

  body: {
    '& tr td': {
      verticalAlign: 'top',
      borderBottom: 'none !important',
    },
    '& tr > td:nth-of-type(1)': {
      padding: '7px 10px 7px 0px !important',
    },
    '& tr td:nth-of-type(2)': {
      padding: '7px 0px 7px 10px',
    },
  },
}));

const labels = {
  nameVn: 'Tên',
  nameEn: 'Name',
};

function ProjectType() {
  const { classes, cx } = useStyles();
  const dispatch = useAppDispatch();
  const listProjectType = useAppSelector((state) => state.projectType.projectType);
  const [openedModalAddInfo, setOpenedModalAddInfo] = useState(false);
  const [openedModalInfo, setOpenedModalInfo] = useState({
    index: 0,
    isOpen: false,
  });
  const [scrolled, setScrolled] = useState(false);
  const { height } = useViewportSize();
  const { ref, height: elementHeight } = useElementSize();
  const [openedModalEditInfo, setOpenedModalEditInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm({
    initialValues: {
      nameVn: '', //Name of the project bỏ phần title
      nameEn: '', //Name of the project bỏ phần title
      createdTime: Date.now(),
      createdUser: 'admin',
      modifiedTime: Date.now(),
      modifiedUser: 'admin',
    },
  });
  const getProjectType = () => {
    dispatch(getProjectTypesApi());
  };
  useEffect(() => {
    getProjectType();
  }, []);

  return (
    <Paper shadow="xl" p="md">
      <Button
        onClick={() => {
          setOpenedModalAddInfo(true);
        }}
      >
        Tạo mới
      </Button>
      <ScrollArea
        sx={{ height: elementHeight >= 0.72 * height ? '72vh' : 'auto' }}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      >
        <Table ref={ref} highlightOnHover withColumnBorders>
          <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
            <tr>
              <th>{labels.nameVn}</th>
              <th style={{ width: 320 }}>Chức năng</th>
            </tr>
          </thead>
          <tbody>
            {listProjectType?.length ? (
              listProjectType.map((projectType, index, arr) => (
                <tr key={projectType.id}>
                  <td>
                    <Anchor
                      onClick={() => {
                        setOpenedModalInfo({
                          index,
                          isOpen: true,
                        });
                      }}
                    >
                      {projectType.nameVn}
                    </Anchor>
                  </td>
                  <td>
                    <Group grow>
                      <Button
                        onClick={() => {
                          setOpenedModalInfo({
                            index,
                            isOpen: true,
                          });
                        }}
                      >
                        Xem
                      </Button>
                      <Button
                        onClick={() => {
                          form.setValues(projectType as any);
                          setOpenedModalEditInfo(true);
                        }}
                      >
                        Sửa
                      </Button>
                      <Button
                        onClick={() => {
                          openConfirmModal({
                            title: 'Tiếp tục',
                            children: <Text size="sm">Bạn có muốn xoá không?</Text>,
                            labels: { confirm: 'Xoá', cancel: 'Huỷ bỏ' },
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onCancel: () => {},
                            onConfirm: () => {
                              deleteProjectTypeDetail(projectType.id).then(() => {
                                getProjectType();
                                showNotification({
                                  title: 'Thành công',
                                  message: 'Xoá thành công',
                                  color: 'green',
                                  autoClose: 5000,
                                });
                              });
                            },
                          });
                        }}
                      >
                        Xoá
                      </Button>
                    </Group>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={100}>
                  <Empty />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </ScrollArea>
      <Modal
        opened={openedModalInfo.isOpen}
        onClose={() => {
          setOpenedModalInfo({
            index: 0,
            isOpen: false,
          });
        }}
        size="100%"
        title="Thông tin chi tiết"
      >
        <Table>
          <tbody>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </Table>
      </Modal>
      <Modal
        opened={openedModalAddInfo}
        onClose={() => {
          setOpenedModalAddInfo(false);
        }}
        size="100%"
        title="Thêm mới"
        closeOnClickOutside={false}
      >
        <form
          onSubmit={form.onSubmit((values) => {
            setIsLoading(true);
            postProjectTypeDetail({
              ...values,
              id: undefined,
            })
              .then(() => {
                setIsLoading(false);
                showNotification({
                  title: 'Thành công',
                  message: 'Thêm mới thành công',
                  color: 'green',
                  autoClose: 5000,
                });
                getProjectType();
                form.setValues({
                  nameVn: '',
                  nameEn: '',
                  createdTime: Date.now(),
                  createdUser: 'admin',
                  modifiedTime: Date.now(),
                  modifiedUser: 'admin',
                });
                setOpenedModalAddInfo(false);
              })
              .catch(() => {
                setIsLoading(false);
              });
          })}
        >
          <Table withColumnBorders>
            <thead>
              <tr>
                <th style={{ width: '50%' }}>Tiếng Việt</th>
                <th style={{ width: '50%' }}>Tiếng Anh</th>
              </tr>
            </thead>
            <tbody className={classes.body}>
              <tr>
                <td>
                  <TextInput
                    required
                    placeholder={labels.nameVn}
                    label={labels.nameVn}
                    {...form.getInputProps('nameVn')}
                  />
                </td>
                <td>
                  <TextInput
                    required
                    placeholder={labels.nameEn}
                    label={labels.nameEn}
                    {...form.getInputProps('nameEn')}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
          <Divider mt="xs" />
          <Group position="right" mt="xs">
            <Button
              variant="default"
              onClick={() => {
                form.setValues({
                  nameVn: '',
                  nameEn: '',
                  createdTime: Date.now(),
                  createdUser: 'admin',
                  modifiedTime: Date.now(),
                  modifiedUser: 'admin',
                });
                setOpenedModalAddInfo(false);
              }}
              disabled={isLoading}
            >
              Huỷ bỏ
            </Button>
            <Button type="submit" loading={isLoading}>
              Thêm mới
            </Button>
          </Group>
        </form>
      </Modal>
      <Modal
        opened={openedModalEditInfo}
        onClose={() => {
          setOpenedModalEditInfo(false);
        }}
        size="100%"
        title="Chỉnh sửa"
        closeOnClickOutside={false}
      >
        <form
          onSubmit={form.onSubmit((values) => {
            setIsLoading(true);
            putProjectTypeDetail({
              ...values,
            })
              .then(() => {
                setIsLoading(false);
                showNotification({
                  title: 'Thành công',
                  message: 'Chỉnh sửa thành công',
                  color: 'green',
                  autoClose: 5000,
                });
                getProjectType();
                form.setValues({
                  nameVn: '',
                  nameEn: '',
                  createdTime: Date.now(),
                  createdUser: 'admin',
                  modifiedTime: Date.now(),
                  modifiedUser: 'admin',
                });
                setOpenedModalEditInfo(false);
              })
              .catch(() => {
                setIsLoading(false);
              });
          })}
        >
          <Table withColumnBorders>
            <thead>
              <tr>
                <th style={{ width: '50%' }}>Tiếng Việt</th>
                <th style={{ width: '50%' }}>Tiếng Anh</th>
              </tr>
            </thead>
            <tbody className={classes.body}>
              <tr>
                <td>
                  <TextInput
                    required
                    placeholder={labels.nameVn}
                    label={labels.nameVn}
                    {...form.getInputProps('nameVn')}
                  />
                </td>
                <td>
                  <TextInput
                    required
                    placeholder={labels.nameEn}
                    label={labels.nameEn}
                    {...form.getInputProps('nameEn')}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
          <Divider mt="xs" />
          <Group position="right" mt="xs">
            <Button
              variant="default"
              onClick={() => {
                form.setValues({
                  nameVn: '',
                  nameEn: '',
                  createdTime: Date.now(),
                  createdUser: 'admin',
                  modifiedTime: Date.now(),
                  modifiedUser: 'admin',
                });
                setOpenedModalEditInfo(false);
              }}
              disabled={isLoading}
            >
              Huỷ bỏ
            </Button>
            <Button type="submit" loading={isLoading}>
              Chỉnh sửa
            </Button>
          </Group>
        </form>
      </Modal>
    </Paper>
  );
}

export default ProjectType;
