import { getIntroduction } from '@apis/introductionApi';
import { imageType } from '@constants/types';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export type userType = {
  id?: number;
  username: string;
  password: string;
  email?: string;
  phone?: string;
  isDeleted?: boolean;
  createdTime: string;
  modifiedTime: string;
  role?: string;
};

interface authState {
  isAuth: boolean;
  userDetail: userType | null;
  token: string;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState = {
  isAuth: !!localStorage.getItem('token'),
  userDetail: null,
  token: localStorage.getItem('token') || '',
  loading: 'idle',
} as authState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuth: (state, action) => {
      state.isAuth = action.payload;
    },
    setUserDetail: (state, action: PayloadAction<userType>) => {
      state.userDetail = action.payload;
    },
    logOut: (state) => {
      state.isAuth = false;
      state.userDetail = null;
      localStorage.setItem('token', '');
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(getAboutApi.pending, (state) => {
    //   state.loading = 'pending';
    // });
    // builder.addCase(getAboutApi.fulfilled, (state, action: PayloadAction<any>) => {
    //   const data = action.payload;
    //   if (Array.isArray(data)) {
    //     state.about = data;
    //     state.loading = 'succeeded';
    //   }
    // });
    // builder.addCase(getAboutApi.rejected, (state, action: PayloadAction<any>) => {
    //   state.loading = 'failed';
    //   console.error('Get about failed!');
    // });
  },
});

// export actions
export const { setIsAuth, setUserDetail, logOut, setToken } = authSlice.actions;

export default authSlice.reducer;
