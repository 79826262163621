import imageCompression from 'browser-image-compression';

const configs = {
  BASE_API_URL: import.meta.env.VITE_BASE_API_URL,
  BASE_IMAGE_URL: import.meta.env.VITE_BASE_IMAGE_URL,
  APP_NAME: import.meta.env.VITE_APP_NAME,
};

export const compressFile = async (file: any) => {
  const options = {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  return await imageCompression(file, options);
};

export default configs;
