import {
  deleteProjectDetail,
  postProjectDetail,
  postProjectTypeDetail,
  putProjectDetail,
} from '@apis/projectApi';
import {
  Anchor,
  Badge,
  Box,
  Button,
  createStyles,
  Divider,
  FileButton,
  Group,
  Image,
  Input,
  LoadingOverlay,
  Modal,
  MultiSelect,
  NumberInput,
  Paper,
  ScrollArea,
  Select,
  Switch,
  Table,
  Text,
  TextInput,
  TypographyStylesProvider,
} from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useElementSize, useViewportSize } from '@mantine/hooks';
import { openConfirmModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import RichTextEditor from '@mantine/rte';
import { getProjectTypesApi } from 'redux/reducer/projectType.slice';
import 'dayjs/locale/vi';
import 'lazysizes';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hook';
import { getProjectApi, projectInterface } from 'redux/reducer/projects.slice';
import { getServiceApi } from 'redux/reducer/service.slice';
import LazyLoadComponent from '@components/LazyLoadComponent/LazyLoadComponent';
import Empty from '@components/Empty/Empty';
import configs, { compressFile } from '@constants/configs';

const useStyles = createStyles((theme) => ({
  header: {
    position: 'sticky',
    top: 0,
    zIndex: 10,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    transition: 'box-shadow 150ms ease',

    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2]
      }`,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },

  body: {
    '& tr td': {
      verticalAlign: 'top',
      borderBottom: 'none !important',
    },
    '& tr > td:nth-of-type(1)': {
      padding: '7px 10px 7px 0px !important',
    },
    '& tr td:nth-of-type(2)': {
      padding: '7px 0px 7px 10px',
    },
  },
}));

const MAX_FILE_LENGTH = Infinity;

const statuses = [
  {
    status: 'Đang tiến hành',
    color: 'yellow',
  },
  {
    status: 'Hoàn thành',
    color: 'green',
  },
  { status: 'Thất bại', color: 'red' },
];

const labels = {
  nameVn: 'Tên',
  nameEn: 'Title',
  contentVn: 'Mô tả',
  contentEn: 'Description',
  addressVn: 'Địa chỉ',
  addressEn: 'Address',
  areaVn: 'Diện tích (m²)',
  areaEn: 'Area (m²)',
  durationVn: 'Thời gian thi công (tháng)',
  durationEn: 'Duration (month)',
  investorVn: 'Nhà đầu tư',
  investorEn: 'Investor',
  startVn: 'Bắt đầu',
  startEn: 'Start',
  mainContractorVn: 'Nhà thầu chính',
  mainContractorEn: 'Main Contractor',
  serviceVn: 'Dịch vụ',
  serviceEn: 'Service',
  statusVn: 'Trạng thái',
  projectTypeVn: 'Loại dự án',
  projectTypeEn: 'Project Type',
  statusEn: 'Status',
  image: 'Ảnh',
};

function Project() {
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState<File[]>([]);
  const listProject = [...useAppSelector((state) => state.projects.project)]
    .sort(
      (a: projectInterface, b: projectInterface) =>
        (b?.createdTime?.localeCompare(a?.createdTime ?? '') && a?.id - b?.id) ?? 1,
    )
    .reverse();
  const listService = useAppSelector((state) => state.service.service);
  const listProjectType = useAppSelector((state) => state.projectType.projectType);
  const [openedModalAddInfo, setOpenedModalAddInfo] = useState(false);
  const [openedModalInfo, setOpenedModalInfo] = useState({
    index: 0,
    isOpen: false,
  });
  const { classes, cx } = useStyles();
  const [scrolled, setScrolled] = useState(false);
  const { height } = useViewportSize();
  const { ref, height: elementHeight } = useElementSize();
  const [openedModalEditInfo, setOpenedModalEditInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const form = useForm({
    initialValues: {
      status: 0,
      nameVn: '',
      nameEn: '',
      contentVn: '',
      contentEn: '',
      addressVn: '',
      addressEn: '',
      area: undefined,
      duration: undefined,
      investorVn: '',
      investorEn: '',
      start: '',
      mainContractorVn: '',
      mainContractorEn: '',
      priority: listProject.length || 0,
      isShow: true,
      projectType: {},
      createdTime: Date.now(),
      createdUser: 'admin',
      modifiedTime: Date.now(),
      modifiedUser: 'admin',
      isDeleted: false,
      services: [] as any,
      images: [],
    } as any,
  });

  const formProjectType = useForm({
    initialValues: {
      nameVn: '', //Name of the project bỏ phần title
      nameEn: '', //Name of the project bỏ phần title
      createdTime: Date.now(),
      createdUser: 'admin',
      modifiedTime: Date.now(),
      modifiedUser: 'admin',
    },
  });
  const [images, setImages] = useState<any>([]);
  // const images: any = [];
  const getProject = () => {
    dispatch(getProjectApi());
  };
  const getService = () => {
    dispatch(getServiceApi());
  };
  const getProjectType = () => {
    dispatch(getProjectTypesApi());
  };

  const handleFileChosen = async (file: any) => {
    await setIsLoadingFile(true);
    const fileResult = await compressFile(file);
    await setIsLoadingFile(false);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(fileResult);
    });
  };

  useEffect(() => {
    getService();
    getProject();
    getProjectType();
  }, []);
  // useDidUpdate(() => {
  //   dispatch(
  //     addService(
  //       listProject.flatMap((project) =>
  //         project.services.filter((service: any) => service.isDeleted),
  //       ),
  //     ),
  //   );
  //   // listProject.flatMap((project)=> project.services.filter((service: any)=>service.isDeleted));
  // }, []);
  useEffect(() => {
    Promise.all(
      files.map((file) =>
        handleFileChosen(file).then((res) => ({
          name: file.name,
          url: res,
          type: file.type,
        })),
      ),
    ).then((res) => {
      setImages(res);
    });
  }, [files]);

  useEffect(() => {
    form.setValues({ priority: listProject.length });
  }, [listProject]);
  return (
    <Paper shadow="xl" p="md">
      <Button
        onClick={() => {
          form.setValues({
            status: 0,
            nameVn: '',
            nameEn: '',
            contentVn: '',
            contentEn: '',
            addressVn: '',
            addressEn: '',
            area: undefined,
            duration: undefined,
            investorVn: '',
            investorEn: '',
            start: '',
            mainContractorVn: '',
            mainContractorEn: '',
            projectType: {},
            priority: listProject.length || 0,
            isShow: true,
            createdTime: Date.now(),
            createdUser: 'admin',
            modifiedTime: Date.now(),
            modifiedUser: 'admin',
            isDeleted: false,
            services: [],
            images: [],
          });
          setOpenedModalAddInfo(true);
        }}
      >
        Tạo mới
      </Button>
      <ScrollArea
        sx={{ height: elementHeight >= 0.72 * height ? '72vh' : 'auto' }}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      >
        <Table ref={ref} highlightOnHover withColumnBorders>
          <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
            <tr>
              <th style={{ width: 40 }}>Hiển thị</th>
              <th>{labels.nameVn}</th>
              <th style={{ width: 100 }}>{labels.statusVn}</th>
              <th style={{ width: '20%' }}>{labels.image}</th>
              {/* <th style={{ width: 100 }}>Độ ưu tiên</th> */}
              <th style={{ width: 320 }}>Chức năng</th>
            </tr>
          </thead>
          <tbody>
            {listProject?.length ? (
              listProject.map((project, index, arr) => (
                <tr key={project.id}>
                  <td>
                    <Switch
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      checked={project.isShow}
                      onClick={(event: any) => {
                        const data = new FormData();
                        const listServiceClone = [
                          ...listService,
                          ...listProject.flatMap((project) =>
                            project.services.filter((service: any) => service.isDeleted),
                          ),
                        ];
                        const listServices = [
                          ...project.services.map((service: any) => ({
                            ...listServiceClone.find((s) => service.id === s.id),
                            // projects: {
                            //   ...project,
                            // },
                          })),
                        ];
                        data.append(
                          'file',
                          new Blob(undefined, {
                            type: 'multipart/form-data',
                          }),
                        );
                        data.append(
                          'projectvo',
                          new Blob(
                            [
                              JSON.stringify({
                                ...project,
                                isShow: event.target.checked,
                                services: listServices,
                                status: project.status === 'in progress' ? 0 : 1,
                              }),
                            ],
                            {
                              type: 'application/json',
                            },
                          ),
                        );
                        putProjectDetail(data).then(() => {
                          getProject();
                        });
                      }}
                    />
                  </td>
                  <td>
                    <Anchor
                      onClick={() => {
                        setOpenedModalInfo({
                          index,
                          isOpen: true,
                        });
                      }}
                    >
                      {project.nameVn}
                    </Anchor>
                  </td>
                  <td>
                    <Badge
                      color={
                        statuses?.[
                          ['in progress', 'completed', 'failed'].findIndex((status) =>
                            status.includes(project?.status),
                          )
                        ]?.color
                      }
                      variant="filled"
                    >
                      {
                        statuses[
                          ['in progress', 'completed', 'failed'].findIndex((status) =>
                            status.includes(project?.status),
                          )
                        ].status
                      }
                    </Badge>
                  </td>
                  <td>
                    <LazyLoadComponent>
                      <Image
                        imageProps={
                          {
                            'data-src': `${configs.BASE_IMAGE_URL}${project?.images[0]?.url}`,
                          } as any
                        }
                        classNames={{
                          image: 'lazyload',
                        }}
                        src={`${configs.BASE_IMAGE_URL}${project?.images[0]?.url}`}
                        withPlaceholder
                      />
                    </LazyLoadComponent>
                  </td>
                  {/* <td>
                    <Group>
                      <ActionIcon
                        onClick={() => {
                          const data = new FormData();
                          data.append(
                            'file',
                            new Blob(undefined, {
                              type: 'multipart/form-data',
                            }),
                          );
                          data.append(
                            'projectvo',
                            new Blob(
                              [
                                JSON.stringify({
                                  ...project,
                                  status: project.status === 'in progress' ? 0 : 1,
                                  priority: index > 1 ? arr.at(index - 1)!.priority! - 1 : 0,
                                }),
                              ],
                              {
                                type: 'application/json',
                              },
                            ),
                          );
                          putProjectDetail(data).then(() => {
                            getProject();
                          });
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-arrow-up"
                          width={44}
                          height={44}
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="#2c3e50"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <line x1={12} y1={5} x2={12} y2={19} />
                          <line x1={18} y1={11} x2={12} y2={5} />
                          <line x1={6} y1={11} x2={12} y2={5} />
                        </svg>
                      </ActionIcon>
                      <ActionIcon
                        onClick={() => {
                          const data = new FormData();
                          data.append(
                            'file',
                            new Blob(undefined, {
                              type: 'multipart/form-data',
                            }),
                          );
                          data.append(
                            'projectvo',
                            new Blob(
                              [
                                JSON.stringify({
                                  ...project,
                                  status: project.status === 'in progress' ? 0 : 1,
                                  priority:
                                    index < arr.length
                                      ? arr.at(index + 1)!.priority! + 1
                                      : arr.length,
                                }),
                              ],
                              {
                                type: 'application/json',
                              },
                            ),
                          );
                          putProjectDetail(data).then(() => {
                            getProject();
                          });
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-arrow-down"
                          width={44}
                          height={44}
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="#2c3e50"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <line x1={12} y1={5} x2={12} y2={19} />
                          <line x1={18} y1={13} x2={12} y2={19} />
                          <line x1={6} y1={13} x2={12} y2={19} />
                        </svg>
                      </ActionIcon>
                    </Group>
                  </td> */}
                  <td>
                    <Group grow>
                      <Button
                        onClick={() => {
                          setOpenedModalInfo({
                            index,
                            isOpen: true,
                          });
                        }}
                      >
                        Xem
                      </Button>
                      <Button
                        onClick={() => {
                          // eslint-disable-next-line
                          form.setValues({
                            ...project,
                            status: project.status === 'in progress' ? 0 : 1,
                            projectType: project?.projectType?.id,
                            services: [...(project.services.map((service) => service.id) as any)],
                          });
                          setImages(project.images);
                          setOpenedModalEditInfo(true);
                        }}
                      >
                        Sửa
                      </Button>
                      <Button
                        onClick={() => {
                          openConfirmModal({
                            title: 'Tiếp tục',
                            children: <Text size="sm">Bạn có muốn xoá không?</Text>,
                            labels: { confirm: 'Xoá', cancel: 'Huỷ bỏ' },
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onCancel: () => {},
                            onConfirm: () => {
                              deleteProjectDetail(project.id).then(() => {
                                getProject();
                                showNotification({
                                  title: 'Thành công',
                                  message: 'Xoá thành công',
                                  color: 'green',
                                  autoClose: 5000,
                                });
                              });
                            },
                          });
                        }}
                      >
                        Xoá
                      </Button>
                    </Group>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={100}>
                  <Empty />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </ScrollArea>
      <Modal
        opened={openedModalInfo.isOpen}
        onClose={() => {
          setOpenedModalInfo({
            index: 0,
            isOpen: false,
          });
        }}
        size="100%"
        title="Thông tin chi tiết"
      >
        <Table>
          <tbody>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </Table>
        <TypographyStylesProvider>
          <div
            dangerouslySetInnerHTML={{
              __html: listProject?.at(openedModalInfo.index)?.contentVn || '',
            }}
          />
        </TypographyStylesProvider>
      </Modal>
      {listProjectType?.length ? (
        <>
          <Modal
            opened={openedModalAddInfo}
            onClose={() => {
              setOpenedModalAddInfo(false);
            }}
            size="100%"
            title="Thêm mới"
            closeOnClickOutside={false}
          >
            <LoadingOverlay visible={isLoadingFile} overlayBlur={2} />
            <form
              onSubmit={form.onSubmit((values) => {
                const data = new FormData();
                images.forEach((image: any) => {
                  const base64 = image.url.split(';base64,');
                  const byteCharacters = atob(base64[1]);
                  const byteNumbers = new Array(byteCharacters.length);
                  for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                  }
                  const byteArray = new Uint8Array(byteNumbers);
                  data.append('file', new Blob([byteArray], { type: image.type }), image.name);
                });
                if (images.length === 0)
                  data.append(
                    'file',
                    new Blob(undefined, {
                      type: 'multipart/form-data',
                    }),
                  );
                const listImages = [
                  ...files.map(() => ({
                    createdTime: Date.now(),
                    createdUser: 'admin',
                    modifiedTime: Date.now(),
                    modifiedUser: 'admin',
                  })),
                ];
                const listServiceClone = [
                  ...listService,
                  ...listProject.flatMap((project) =>
                    project.services.filter((service: any) => service.isDeleted),
                  ),
                ];
                const listServices = [
                  ...values.services.map((service: any) => ({
                    ...listServiceClone.find((s) => service === s.id),
                    // projects: {
                    //   ...values,
                    // },
                  })),
                ];
                const projectType = listProjectType.find((item) => item.id === values.projectType);
                data.append(
                  'projectvo',
                  new Blob(
                    [
                      JSON.stringify({
                        ...values,
                        id: undefined,
                        images: listImages,
                        services: listServices,
                        projectType,
                      }),
                    ],
                    {
                      type: 'application/json',
                    },
                  ),
                );
                setIsLoading(true);
                postProjectDetail(data)
                  .then(() => {
                    setIsLoading(false);
                    showNotification({
                      title: 'Thành công',
                      message: 'Thêm mới thành công',
                      color: 'green',
                      autoClose: 5000,
                    });
                    setFiles([]);
                    getProject();
                    setOpenedModalAddInfo(false);
                  })
                  .catch(() => {
                    setIsLoading(false);
                  });
              })}
            >
              <Table withColumnBorders>
                <thead>
                  <tr>
                    <th style={{ width: '50%' }}>Tiếng Việt</th>
                    <th style={{ width: '50%' }}>Tiếng Anh</th>
                  </tr>
                </thead>
                <tbody className={classes.body}>
                  <tr>
                    <td>
                      <TextInput
                        required
                        placeholder={labels.nameVn}
                        label={labels.nameVn}
                        {...form.getInputProps('nameVn')}
                      />
                    </td>
                    <td>
                      <TextInput
                        required
                        placeholder={labels.nameEn}
                        label={labels.nameEn}
                        {...form.getInputProps('nameEn')}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Select
                        required
                        data={[
                          ...listProjectType.map((projectType) => ({
                            value: projectType.id,
                            label: projectType.nameVn,
                          })),
                        ]}
                        placeholder={labels.projectTypeVn}
                        label={labels.projectTypeVn}
                        {...form.getInputProps('projectType')}
                      />
                    </td>
                    <td>
                      <Select
                        required
                        data={[
                          ...listProjectType.map((projectType) => ({
                            value: projectType.id,
                            label: projectType.nameEn,
                          })),
                        ]}
                        placeholder={labels.projectTypeEn}
                        label={labels.projectTypeEn}
                        {...form.getInputProps('projectType')}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <TextInput
                        placeholder={labels.addressVn}
                        label={labels.addressVn}
                        {...form.getInputProps('addressVn')}
                      />
                    </td>
                    <td>
                      <TextInput
                        placeholder={labels.addressEn}
                        label={labels.addressEn}
                        {...form.getInputProps('addressEn')}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Input.Wrapper id="contentVn" label={labels.contentVn}>
                        <RichTextEditor
                          sticky={true}
                          style={{
                            height: 500,
                            overflowY: 'auto',
                          }}
                          id="contentVn"
                          controls={[
                            ['bold', 'italic', 'underline'],
                            ['h1', 'h2', 'h3', 'h4'],
                            ['alignLeft', 'alignCenter', 'alignRight'],
                            ['unorderedList', 'orderedList'],
                            ['sup', 'sub'],
                            ['link', 'clean'],
                          ]}
                          placeholder={labels.contentVn}
                          label={labels.contentVn}
                          {...form.getInputProps('contentVn')}
                        />
                      </Input.Wrapper>
                    </td>
                    <td>
                      <Input.Wrapper id="contentEn" label={labels.contentEn}>
                        <RichTextEditor
                          sticky={true}
                          style={{
                            height: 500,
                            overflowY: 'auto',
                          }}
                          id="contentEn"
                          controls={[
                            ['bold', 'italic', 'underline'],
                            ['h1', 'h2', 'h3', 'h4'],
                            ['alignLeft', 'alignCenter', 'alignRight'],
                            ['unorderedList', 'orderedList'],
                            ['sup', 'sub'],
                            ['link', 'clean'],
                          ]}
                          placeholder={labels.contentEn}
                          label={labels.contentEn}
                          {...form.getInputProps('contentEn')}
                        />
                      </Input.Wrapper>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Select
                        placeholder={labels.statusVn}
                        label={labels.statusVn}
                        {...form.getInputProps('status')}
                        data={[
                          { value: 0, label: 'Đang xử lý' },
                          { value: 1, label: 'Hoàn thành' },
                        ]}
                      />
                    </td>
                    <td>
                      <Select
                        placeholder={labels.statusEn}
                        label={labels.statusEn}
                        {...form.getInputProps('status')}
                        data={[
                          { value: 0, label: 'In progress' },
                          { value: 1, label: 'Completed' },
                        ]}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <NumberInput
                        placeholder={labels.areaVn}
                        label={labels.areaVn}
                        hideControls
                        parser={(value: any) => value.replace(/(,*)/g, '')}
                        formatter={(value: any) =>
                          !Number.isNaN(parseFloat(value))
                            ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            : ''
                        }
                        {...form.getInputProps('area')}
                      />
                    </td>
                    <td>
                      <NumberInput
                        placeholder={labels.areaEn}
                        label={labels.areaEn}
                        hideControls
                        parser={(value: any) => value.replace(/(,*)/g, '')}
                        formatter={(value: any) =>
                          !Number.isNaN(parseFloat(value))
                            ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            : ''
                        }
                        {...form.getInputProps('area')}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <DatePicker
                        styles={{
                          calendarBase: {
                            maxWidth: '100%',
                          },
                        }}
                        placeholder={labels.startVn}
                        label={labels.startVn}
                        locale="vi"
                        inputFormat="DD/MM/YYYY"
                        {...form.getInputProps('start')}
                      />
                    </td>
                    <td>
                      <DatePicker
                        styles={{
                          calendarBase: {
                            maxWidth: '100%',
                          },
                        }}
                        placeholder={labels.startEn}
                        label={labels.startEn}
                        locale="vi"
                        inputFormat="DD/MM/YYYY"
                        {...form.getInputProps('start')}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <NumberInput
                        placeholder={labels.durationVn}
                        label={labels.durationVn}
                        hideControls
                        parser={(value: any) => value.replace(/(,*)/g, '')}
                        formatter={(value: any) =>
                          !Number.isNaN(parseFloat(value))
                            ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            : ''
                        }
                        {...form.getInputProps('duration')}
                      />
                    </td>
                    <td>
                      <NumberInput
                        placeholder={labels.durationEn}
                        label={labels.durationEn}
                        hideControls
                        parser={(value: any) => value.replace(/(,*)/g, '')}
                        formatter={(value: any) =>
                          !Number.isNaN(parseFloat(value))
                            ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            : ''
                        }
                        {...form.getInputProps('duration')}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <TextInput
                        placeholder={labels.investorVn}
                        label={labels.investorVn}
                        {...form.getInputProps('investorVn')}
                      />
                    </td>
                    <td>
                      <TextInput
                        placeholder={labels.investorEn}
                        label={labels.investorEn}
                        {...form.getInputProps('investorEn')}
                      />
                    </td>
                  </tr>
                  {/* <tr>
                    <td>
                      <TextInput
                        placeholder={labels.mainContractorVn}
                        label={labels.mainContractorVn}
                        {...form.getInputProps('mainContractorVn')}
                      />
                    </td>
                    <td>
                      <TextInput
                        placeholder={labels.mainContractorEn}
                        label={labels.mainContractorEn}
                        {...form.getInputProps('mainContractorEn')}
                      />
                    </td>
                  </tr> */}
                  <tr>
                    <td>
                      <MultiSelect
                        data={[
                          ...listService
                            .filter((service: any) => !service.isDeleted)
                            .map((service) => ({
                              value: service.id,
                              label: service.nameVn,
                            })),
                        ]}
                        placeholder={labels.serviceVn}
                        label={labels.serviceVn}
                        {...form.getInputProps('services')}
                      />
                    </td>
                    <td>
                      <MultiSelect
                        data={[
                          ...listService
                            .filter((service: any) => !service.isDeleted)
                            .map((service) => ({
                              value: service.id,
                              label: service.nameVn,
                            })),
                        ]}
                        placeholder={labels.serviceEn}
                        label={labels.serviceEn}
                        {...form.getInputProps('services')}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Input.Wrapper label={labels.image}>
                <Group mt={6}>
                  <FileButton onChange={setFiles} accept="image/png,image/jpeg" multiple>
                    {(props) => (
                      <Box
                        {...props}
                        sx={{
                          width: 100,
                          height: 100,
                          border: '3px dashed black',
                          borderRadius: 6,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        <svg
                          viewBox="0 0 24 24"
                          height={48}
                          width={48}
                          aria-hidden="true"
                          focusable="false"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          className="StyledIconBase-ea9ulj-0 bWRyML"
                        >
                          <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z" />
                        </svg>
                      </Box>
                    )}
                  </FileButton>
                  {images.map((file: any, index: any) => (
                    <LazyLoadComponent key={index}>
                      <Image
                        imageProps={
                          {
                            'data-src': files.length
                              ? file?.url
                              : configs.BASE_IMAGE_URL + file?.url,
                          } as any
                        }
                        classNames={{
                          image: 'lazyload',
                        }}
                        width={100}
                        height={100}
                        radius={6}
                        withPlaceholder
                        src={files.length ? file?.url : configs.BASE_IMAGE_URL + file?.url}
                      ></Image>
                    </LazyLoadComponent>
                  ))}
                </Group>
              </Input.Wrapper>
              <Divider mt="xs" />
              <Group position="right" mt="xs">
                <Button
                  variant="default"
                  onClick={() => {
                    setOpenedModalAddInfo(false);
                  }}
                  disabled={isLoading}
                >
                  Huỷ bỏ
                </Button>
                <Button type="submit" loading={isLoading}>
                  Thêm mới
                </Button>
              </Group>
            </form>
          </Modal>
          <Modal
            opened={openedModalEditInfo}
            onClose={() => {
              setOpenedModalEditInfo(false);
            }}
            size="100%"
            title="Chỉnh sửa"
            closeOnClickOutside={false}
          >
            <LoadingOverlay visible={isLoadingFile} overlayBlur={2} />
            <form
              onSubmit={form.onSubmit((values) => {
                const data = new FormData();
                if (files.length) {
                  images.forEach((image: any) => {
                    const base64 = image.url.split(';base64,');
                    const byteCharacters = atob(base64[1]);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                      byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    data.append('file', new Blob([byteArray], { type: image.type }), image.name);
                  });
                  const listImages = [
                    ...files.map(() => ({
                      url: '',
                      createdTime: Date.now(),
                      createdUser: 'admin',
                      modifiedTime: Date.now(),
                      modifiedUser: 'admin',
                    })),
                  ];
                  const listServiceClone = [
                    ...listService,
                    ...listProject.flatMap((project) =>
                      project.services.filter((service: any) => service.isDeleted),
                    ),
                  ];
                  const listServices = [
                    ...values.services.map((service: any) => ({
                      ...listServiceClone.find((s) => service === s.id),
                      // projects: {
                      //   ...values,
                      // },
                    })),
                  ];
                  const projectType = listProjectType.find(
                    (item) => item.id === values.projectType,
                  );
                  data.append(
                    'projectvo',
                    new Blob(
                      [
                        JSON.stringify({
                          ...values,
                          images: listImages,
                          services: listServices,
                          projectType,
                        }),
                      ],
                      {
                        type: 'application/json',
                      },
                    ),
                  );
                } else {
                  data.append(
                    'file',
                    new Blob(undefined, {
                      type: 'multipart/form-data',
                    }),
                  );
                  const listServiceClone = [
                    ...listService,
                    ...listProject.flatMap((project) =>
                      project.services.filter((service: any) => service.isDeleted),
                    ),
                  ];
                  const listServices = [
                    ...values.services.map((service: any) => ({
                      ...listServiceClone.find((s) => service === s.id),
                      // projects: {
                      //   ...values,
                      // },
                    })),
                  ];
                  const projectType = listProjectType.find(
                    (item) => item.id === values.projectType,
                  );
                  data.append(
                    'projectvo',
                    new Blob(
                      [
                        JSON.stringify({
                          ...values,
                          services: listServices,
                          projectType,
                        }),
                      ],
                      {
                        type: 'application/json',
                      },
                    ),
                  );
                }
                setIsLoading(true);
                putProjectDetail(data)
                  .then(() => {
                    setIsLoading(false);
                    showNotification({
                      title: 'Thành công',
                      message: 'Chỉnh sửa thành công',
                      color: 'green',
                      autoClose: 5000,
                    });
                    setFiles([]);
                    getProject();
                    setOpenedModalEditInfo(false);
                  })
                  .catch(() => {
                    setIsLoading(false);
                  });
              })}
            >
              <Table withColumnBorders>
                <thead>
                  <tr>
                    <th style={{ width: '50%' }}>Tiếng Việt</th>
                    <th style={{ width: '50%' }}>Tiếng Anh</th>
                  </tr>
                </thead>
                <tbody className={classes.body}>
                  <tr>
                    <td>
                      <TextInput
                        required
                        placeholder={labels.nameVn}
                        label={labels.nameVn}
                        {...form.getInputProps('nameVn')}
                      />
                    </td>
                    <td>
                      <TextInput
                        required
                        placeholder={labels.nameEn}
                        label={labels.nameEn}
                        {...form.getInputProps('nameEn')}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Select
                        required
                        data={[
                          ...listProjectType.map((projectType) => ({
                            value: projectType.id,
                            label: projectType.nameVn,
                          })),
                        ]}
                        placeholder={labels.projectTypeVn}
                        label={labels.projectTypeVn}
                        {...form.getInputProps('projectType')}
                      />
                    </td>
                    <td>
                      <Select
                        required
                        data={[
                          ...listProjectType.map((projectType) => ({
                            value: projectType.id,
                            label: projectType.nameEn,
                          })),
                        ]}
                        placeholder={labels.projectTypeEn}
                        label={labels.projectTypeEn}
                        {...form.getInputProps('projectType')}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <TextInput
                        placeholder={labels.addressVn}
                        label={labels.addressVn}
                        {...form.getInputProps('addressVn')}
                      />
                    </td>
                    <td>
                      <TextInput
                        placeholder={labels.addressEn}
                        label={labels.addressEn}
                        {...form.getInputProps('addressEn')}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Input.Wrapper id="contentVn" label={labels.contentVn}>
                        <RichTextEditor
                          sticky={true}
                          style={{
                            height: 500,
                            overflowY: 'auto',
                          }}
                          id="contentVn"
                          controls={[
                            ['bold', 'italic', 'underline'],
                            ['h1', 'h2', 'h3', 'h4'],
                            ['alignLeft', 'alignCenter', 'alignRight'],
                            ['unorderedList', 'orderedList'],
                            ['sup', 'sub'],
                            ['link', 'clean'],
                          ]}
                          placeholder={labels.contentVn}
                          label={labels.contentVn}
                          {...form.getInputProps('contentVn')}
                        />
                      </Input.Wrapper>
                    </td>
                    <td>
                      <Input.Wrapper id="contentEn" label={labels.contentEn}>
                        <RichTextEditor
                          sticky={true}
                          style={{
                            height: 500,
                            overflowY: 'auto',
                          }}
                          id="contentEn"
                          controls={[
                            ['bold', 'italic', 'underline'],
                            ['h1', 'h2', 'h3', 'h4'],
                            ['alignLeft', 'alignCenter', 'alignRight'],
                            ['unorderedList', 'orderedList'],
                            ['sup', 'sub'],
                            ['link', 'clean'],
                          ]}
                          placeholder={labels.contentEn}
                          label={labels.contentEn}
                          {...form.getInputProps('contentEn')}
                        />
                      </Input.Wrapper>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Select
                        placeholder={labels.statusVn}
                        label={labels.statusVn}
                        {...form.getInputProps('status')}
                        data={[
                          { value: 0, label: 'Đang xử lý' },
                          { value: 1, label: 'Hoàn thành' },
                        ]}
                      />
                    </td>
                    <td>
                      <Select
                        placeholder={labels.statusEn}
                        label={labels.statusEn}
                        {...form.getInputProps('status')}
                        data={[
                          { value: 0, label: 'In progress' },
                          { value: 1, label: 'Completed' },
                        ]}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <NumberInput
                        placeholder={labels.areaVn}
                        label={labels.areaVn}
                        hideControls
                        parser={(value: any) => value.replace(/(,*)/g, '')}
                        formatter={(value: any) =>
                          !Number.isNaN(parseFloat(value))
                            ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            : ''
                        }
                        {...form.getInputProps('area')}
                      />
                    </td>
                    <td>
                      <NumberInput
                        placeholder={labels.areaEn}
                        label={labels.areaEn}
                        hideControls
                        parser={(value: any) => value.replace(/(,*)/g, '')}
                        formatter={(value: any) =>
                          !Number.isNaN(parseFloat(value))
                            ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            : ''
                        }
                        {...form.getInputProps('area')}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <DatePicker
                        styles={{
                          calendarBase: {
                            maxWidth: '100%',
                          },
                        }}
                        placeholder={labels.startVn}
                        label={labels.startVn}
                        inputFormat="DD/MM/YYYY"
                        {...form.getInputProps('start')}
                        value={new Date(form.getInputProps('start').value)}
                      />
                    </td>
                    <td>
                      <DatePicker
                        styles={{
                          calendarBase: {
                            maxWidth: '100%',
                          },
                        }}
                        placeholder={labels.startEn}
                        label={labels.startEn}
                        inputFormat="DD/MM/YYYY"
                        {...form.getInputProps('start')}
                        value={new Date(form.getInputProps('start').value)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <NumberInput
                        placeholder={labels.durationVn}
                        label={labels.durationVn}
                        hideControls
                        parser={(value: any) => value.replace(/(,*)/g, '')}
                        formatter={(value: any) =>
                          !Number.isNaN(parseFloat(value))
                            ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            : ''
                        }
                        {...form.getInputProps('duration')}
                      />
                    </td>
                    <td>
                      <NumberInput
                        placeholder={labels.durationEn}
                        label={labels.durationEn}
                        hideControls
                        parser={(value: any) => value.replace(/(,*)/g, '')}
                        formatter={(value: any) =>
                          !Number.isNaN(parseFloat(value))
                            ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            : ''
                        }
                        {...form.getInputProps('duration')}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <TextInput
                        placeholder={labels.investorVn}
                        label={labels.investorVn}
                        {...form.getInputProps('investorVn')}
                      />
                    </td>
                    <td>
                      <TextInput
                        placeholder={labels.investorEn}
                        label={labels.investorEn}
                        {...form.getInputProps('investorEn')}
                      />
                    </td>
                  </tr>
                  {/* <tr>
                    <td>
                      <TextInput
                        placeholder={labels.mainContractorVn}
                        label={labels.mainContractorVn}
                        {...form.getInputProps('mainContractorVn')}
                      />
                    </td>
                    <td>
                      <TextInput
                        placeholder={labels.mainContractorEn}
                        label={labels.mainContractorEn}
                        {...form.getInputProps('mainContractorEn')}
                      />
                    </td>
                  </tr> */}
                  <tr>
                    <td>
                      <MultiSelect
                        data={[
                          ...listService
                            .filter((service: any) => !service.isDeleted)
                            .map((service) => ({
                              value: service.id,
                              label: service.nameVn,
                            })),
                        ]}
                        placeholder={labels.serviceVn}
                        label={labels.serviceVn}
                        {...form.getInputProps('services')}
                      />
                    </td>
                    <td>
                      <MultiSelect
                        data={[
                          ...listService
                            .filter((service: any) => !service.isDeleted)
                            .map((service) => ({
                              value: service.id,
                              label: service.nameVn,
                            })),
                        ]}
                        placeholder={labels.serviceEn}
                        label={labels.serviceEn}
                        {...form.getInputProps('services')}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Input.Wrapper
                label={labels.image}
                description={`Số hình ảnh tối đa là ${MAX_FILE_LENGTH}`}
              >
                <Group mt={6}>
                  <FileButton onChange={setFiles} accept="image/png,image/jpeg" multiple>
                    {(props) => (
                      <Box
                        {...props}
                        sx={{
                          width: 100,
                          height: 100,
                          border: '3px dashed black',
                          borderRadius: 6,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        <svg
                          viewBox="0 0 24 24"
                          height={48}
                          width={48}
                          aria-hidden="true"
                          focusable="false"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          className="StyledIconBase-ea9ulj-0 bWRyML"
                        >
                          <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z" />
                        </svg>
                      </Box>
                    )}
                  </FileButton>
                  {images.map((file: any, index: any) => (
                    <LazyLoadComponent key={index}>
                      <Image
                        imageProps={
                          {
                            'data-src': files.length
                              ? file?.url
                              : configs.BASE_IMAGE_URL + file?.url,
                          } as any
                        }
                        classNames={{
                          image: 'lazyload',
                        }}
                        width={100}
                        height={100}
                        radius={6}
                        withPlaceholder
                        src={files.length ? file?.url : configs.BASE_IMAGE_URL + file?.url}
                      ></Image>
                    </LazyLoadComponent>
                  ))}
                </Group>
              </Input.Wrapper>
              <Divider mt="xs" />
              <Group position="right" mt="xs">
                <Button
                  variant="default"
                  onClick={() => {
                    setOpenedModalEditInfo(false);
                    setFiles([]);
                  }}
                  disabled={isLoading}
                >
                  Huỷ bỏ
                </Button>
                <Button type="submit" loading={isLoading}>
                  Chỉnh sửa
                </Button>
              </Group>
            </form>
          </Modal>
        </>
      ) : (
        <Modal
          opened={openedModalAddInfo || openedModalEditInfo}
          onClose={() => {
            setOpenedModalAddInfo(false);
            setOpenedModalEditInfo(false);
          }}
          size="100%"
          title="Thêm mới loại dự án"
          closeOnClickOutside={false}
        >
          <form
            onSubmit={formProjectType.onSubmit((values) => {
              setIsLoading(true);
              postProjectTypeDetail({
                ...values,
                id: undefined,
              })
                .then(() => {
                  setIsLoading(false);
                  showNotification({
                    title: 'Thành công',
                    message: 'Thêm mới thành công',
                    color: 'green',
                    autoClose: 5000,
                  });
                  getProjectType();
                  formProjectType.setValues({
                    nameVn: '',
                    nameEn: '',
                    createdTime: Date.now(),
                    createdUser: 'admin',
                    modifiedTime: Date.now(),
                    modifiedUser: 'admin',
                  });
                  setOpenedModalAddInfo(false);
                  setOpenedModalEditInfo(false);
                })
                .catch(() => {
                  setIsLoading(false);
                });
            })}
          >
            <Table withColumnBorders>
              <thead>
                <tr>
                  <th style={{ width: '50%' }}>Tiếng Việt</th>
                  <th style={{ width: '50%' }}>Tiếng Anh</th>
                </tr>
              </thead>
              <tbody className={classes.body}>
                <tr>
                  <td>
                    <TextInput
                      placeholder={labels.nameVn}
                      label={labels.nameVn}
                      {...formProjectType.getInputProps('nameVn')}
                    />
                  </td>
                  <td>
                    <TextInput
                      placeholder={labels.nameEn}
                      label={labels.nameEn}
                      {...formProjectType.getInputProps('nameEn')}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
            <Divider mt="xs" />
            <Group position="right" mt="xs">
              <Button
                variant="default"
                onClick={() => {
                  formProjectType.setValues({
                    nameVn: '',
                    nameEn: '',
                    createdTime: Date.now(),
                    createdUser: 'admin',
                    modifiedTime: Date.now(),
                    modifiedUser: 'admin',
                  });
                  setOpenedModalAddInfo(false);
                  setOpenedModalEditInfo(false);
                }}
                disabled={isLoading}
              >
                Huỷ bỏ
              </Button>
              <Button type="submit" loading={isLoading}>
                Thêm mới
              </Button>
            </Group>
          </form>
        </Modal>
      )}
    </Paper>
  );
}

export default Project;
