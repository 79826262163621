import { deleteServiceDetail, postServiceDetail, putServiceDetail } from '@apis/serviceApi';
import Empty from '@components/Empty/Empty';
import LazyLoadComponent from '@components/LazyLoadComponent/LazyLoadComponent';
import configs, { compressFile } from '@constants/configs';
import {
  ActionIcon,
  Anchor,
  Box,
  Button,
  createStyles,
  Divider,
  FileButton,
  Group,
  Image,
  Input,
  LoadingOverlay,
  Modal,
  Paper,
  ScrollArea,
  Switch,
  Table,
  Text,
  TextInput,
  TypographyStylesProvider,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useElementSize, useViewportSize } from '@mantine/hooks';
import { openConfirmModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import RichTextEditor from '@mantine/rte';
import 'lazysizes';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hook';
import { getServiceApi } from 'redux/reducer/service.slice';

const useStyles = createStyles((theme) => ({
  header: {
    position: 'sticky',
    top: 0,
    zIndex: 10,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    transition: 'box-shadow 150ms ease',

    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2]
      }`,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },

  body: {
    '& tr td': {
      verticalAlign: 'top',
      borderBottom: 'none !important',
    },
    '& tr > td:nth-of-type(1)': {
      padding: '7px 10px 7px 0px !important',
    },
    '& tr td:nth-of-type(2)': {
      padding: '7px 0px 7px 10px',
    },
  },
}));

const MAX_FILE_LENGTH = 2;

const labels = {
  nameVn: 'Tên',
  nameEn: 'Title',
  contentVn: 'Mô tả',
  contentEn: 'Description',
  image: 'Ảnh',
};

function Service() {
  const { classes, cx } = useStyles();
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState<File[]>([]);
  const listService = useAppSelector((state) => state.service.service);
  const [openedModalAddInfo, setOpenedModalAddInfo] = useState(false);
  const [openedModalInfo, setOpenedModalInfo] = useState({
    index: 0,
    isOpen: false,
  });
  const [scrolled, setScrolled] = useState(false);
  const { height } = useViewportSize();
  const { ref, height: elementHeight } = useElementSize();
  const [openedModalEditInfo, setOpenedModalEditInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const form = useForm({
    initialValues: {
      nameVn: '',
      nameEn: '',
      contentVn: '',
      contentEn: '',
      isShow: true,
      priority: listService?.length || 0,
      createdTime: Date.now(),
      createdUser: 'admin',
      modifiedTime: Date.now(),
      modifiedUser: 'admin',
      images: [],
    },
  });
  const [images, setImages] = useState<any>([]);
  // const images: any = [];
  const getService = () => {
    dispatch(getServiceApi());
  };
  const handleFileChosen = async (file: any) => {
    await setIsLoadingFile(true);
    const fileResult = await compressFile(file);
    await setIsLoadingFile(false);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(fileResult);
    });
  };
  useEffect(() => {
    getService();
  }, []);
  useEffect(() => {
    Promise.all(
      files.map((file) =>
        handleFileChosen(file).then((res) => ({
          name: file.name,
          url: res,
          type: file.type,
        })),
      ),
    ).then((res) => {
      setImages(res);
    });
  }, [files]);

  useEffect(() => {
    form.setValues({ priority: listService.length });
  }, [listService]);
  return (
    <Paper shadow="xl" p="md">
      <Button
        onClick={() => {
          form.setValues({
            nameVn: '',
            nameEn: '',
            contentVn: '',
            contentEn: '',
            isShow: true,
            priority: listService?.length || 0,
            createdTime: Date.now(),
            createdUser: 'admin',
            modifiedTime: Date.now(),
            modifiedUser: 'admin',
            images: [],
          });
          setImages([]);
          setOpenedModalAddInfo(true);
        }}
      >
        Tạo mới
      </Button>
      <ScrollArea
        sx={{ height: elementHeight >= 0.72 * height ? '72vh' : 'auto' }}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      >
        <Table ref={ref} highlightOnHover withColumnBorders>
          <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
            <tr>
              <th style={{ width: 40 }}>Hiển thị</th>
              <th>{labels.nameVn}</th>
              <th style={{ width: '20%' }}>{labels.image}</th>
              <th style={{ width: 100 }}>Độ ưu tiên</th>
              <th style={{ width: 320 }}>Chức năng</th>
            </tr>
          </thead>
          <tbody>
            {listService?.length ? (
              listService.map((service, index, arr) => (
                <tr key={service.id}>
                  <td>
                    <Switch
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      checked={service.isShow}
                      onClick={(event: any) => {
                        const data = new FormData();
                        data.append(
                          'file',
                          new Blob(undefined, {
                            type: 'multipart/form-data',
                          }),
                        );
                        data.append(
                          'servicevo',
                          new Blob([JSON.stringify({ ...service, isShow: event.target.checked })], {
                            type: 'application/json',
                          }),
                        );
                        putServiceDetail(data).then(() => {
                          getService();
                        });
                      }}
                    />
                  </td>
                  <td>
                    <Anchor
                      onClick={() => {
                        setOpenedModalInfo({
                          index,
                          isOpen: true,
                        });
                      }}
                    >
                      {service.nameVn}
                    </Anchor>
                  </td>
                  <td>
                    <LazyLoadComponent>
                      <Image
                        imageProps={
                          {
                            'data-src': `${configs.BASE_IMAGE_URL}${service?.images[0]?.url}`,
                          } as any
                        }
                        classNames={{
                          image: 'lazyload',
                        }}
                        src={`${configs.BASE_IMAGE_URL}${service?.images[0]?.url}`}
                        withPlaceholder
                      />
                    </LazyLoadComponent>
                  </td>
                  <td>
                    <Group>
                      <ActionIcon
                        onClick={() => {
                          const data = new FormData();
                          data.append(
                            'file',
                            new Blob(undefined, {
                              type: 'multipart/form-data',
                            }),
                          );
                          data.append(
                            'servicevo',
                            new Blob(
                              [
                                JSON.stringify({
                                  ...service,
                                  priority: index > 1 ? arr.at(index - 1)!.priority! - 1 : 0,
                                }),
                              ],
                              {
                                type: 'application/json',
                              },
                            ),
                          );
                          putServiceDetail(data).then(() => {
                            getService();
                          });
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-arrow-up"
                          width={44}
                          height={44}
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="#2c3e50"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <line x1={12} y1={5} x2={12} y2={19} />
                          <line x1={18} y1={11} x2={12} y2={5} />
                          <line x1={6} y1={11} x2={12} y2={5} />
                        </svg>
                      </ActionIcon>
                      <ActionIcon
                        onClick={() => {
                          const data = new FormData();
                          data.append(
                            'file',
                            new Blob(undefined, {
                              type: 'multipart/form-data',
                            }),
                          );
                          data.append(
                            'servicevo',
                            new Blob(
                              [
                                JSON.stringify({
                                  ...service,
                                  priority:
                                    index < arr.length
                                      ? arr.at(index + 1)!.priority! + 1
                                      : arr.length,
                                }),
                              ],
                              {
                                type: 'application/json',
                              },
                            ),
                          );
                          putServiceDetail(data).then(() => {
                            getService();
                          });
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-arrow-down"
                          width={44}
                          height={44}
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="#2c3e50"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <line x1={12} y1={5} x2={12} y2={19} />
                          <line x1={18} y1={13} x2={12} y2={19} />
                          <line x1={6} y1={13} x2={12} y2={19} />
                        </svg>
                      </ActionIcon>
                    </Group>
                  </td>
                  <td>
                    <Group grow>
                      <Button
                        onClick={() => {
                          setOpenedModalInfo({
                            index,
                            isOpen: true,
                          });
                        }}
                      >
                        Xem
                      </Button>
                      <Button
                        onClick={() => {
                          form.setValues(service as any);
                          setImages(service.images);
                          setOpenedModalEditInfo(true);
                        }}
                      >
                        Sửa
                      </Button>
                      <Button
                        onClick={() => {
                          openConfirmModal({
                            title: 'Tiếp tục',
                            children: <Text size="sm">Bạn có muốn xoá không?</Text>,
                            labels: { confirm: 'Xoá', cancel: 'Huỷ bỏ' },
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onCancel: () => {},
                            onConfirm: () => {
                              deleteServiceDetail(service.id).then(() => {
                                getService();
                                showNotification({
                                  title: 'Thành công',
                                  message: 'Xoá thành công',
                                  color: 'green',
                                  autoClose: 5000,
                                });
                              });
                            },
                          });
                        }}
                      >
                        Xoá
                      </Button>
                    </Group>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={100}>
                  <Empty />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </ScrollArea>
      <Modal
        opened={openedModalInfo.isOpen}
        onClose={() => {
          setOpenedModalInfo({
            index: 0,
            isOpen: false,
          });
        }}
        size="100%"
        title="Thông tin chi tiết"
      >
        <Table>
          <tbody>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </Table>
        <TypographyStylesProvider>
          <div
            dangerouslySetInnerHTML={{
              __html: listService?.at(openedModalInfo.index)?.contentVn || '',
            }}
          />
        </TypographyStylesProvider>
      </Modal>
      <Modal
        opened={openedModalAddInfo}
        onClose={() => {
          setOpenedModalAddInfo(false);
        }}
        size="100%"
        title="Thêm mới"
        closeOnClickOutside={false}
      >
        <LoadingOverlay visible={isLoadingFile} overlayBlur={2} />
        <form
          onSubmit={form.onSubmit((values) => {
            const data = new FormData();
            images.forEach((image: any) => {
              const base64 = image.url.split(';base64,');
              const byteCharacters = atob(base64[1]);
              const byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              data.append('file', new Blob([byteArray], { type: image.type }), image.name);
            });
            if (images.length === 0)
              data.append(
                'file',
                new Blob(undefined, {
                  type: 'multipart/form-data',
                }),
              );
            const listImages = [
              ...files.map((file) => ({
                url: file.name,
                createdTime: Date.now(),
                createdUser: 'admin',
                modifiedTime: Date.now(),
                modifiedUser: 'admin',
                files: null,
              })),
            ];
            data.append(
              'servicevo',
              new Blob([JSON.stringify({ ...values, id: undefined, images: listImages })], {
                type: 'application/json',
              }),
            );
            setIsLoading(true);
            postServiceDetail(data)
              .then(() => {
                setIsLoading(false);
                showNotification({
                  title: 'Thành công',
                  message: 'Thêm mới thành công',
                  color: 'green',
                  autoClose: 5000,
                });
                getService();
                form.setValues({
                  nameVn: '',
                  nameEn: '',
                  contentVn: '',
                  contentEn: '',
                  isShow: true,
                  priority: listService?.length || 0,
                  createdTime: Date.now(),
                  createdUser: 'admin',
                  modifiedTime: Date.now(),
                  modifiedUser: 'admin',
                  images: [],
                });
                setFiles([]);
                setOpenedModalAddInfo(false);
              })
              .catch(() => {
                setIsLoading(false);
              });
          })}
        >
          <Table withColumnBorders>
            <thead>
              <tr>
                <th style={{ width: '50%' }}>Tiếng Việt</th>
                <th style={{ width: '50%' }}>Tiếng Anh</th>
              </tr>
            </thead>
            <tbody className={classes.body}>
              <tr>
                <td>
                  <TextInput
                    required
                    placeholder={labels.nameVn}
                    label={labels.nameVn}
                    {...form.getInputProps('nameVn')}
                  />
                </td>
                <td>
                  <TextInput
                    required
                    placeholder={labels.nameEn}
                    label={labels.nameEn}
                    {...form.getInputProps('nameEn')}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Input.Wrapper id="contentVn" label={labels.contentVn}>
                    <RichTextEditor
                      sticky={true}
                      style={{
                        height: 500,
                        overflowY: 'auto',
                      }}
                      id="contentVn"
                      controls={[
                        ['bold', 'italic', 'underline'],
                        ['h1', 'h2', 'h3', 'h4'],
                        ['alignLeft', 'alignCenter', 'alignRight'],
                        ['unorderedList', 'orderedList'],
                        ['sup', 'sub'],
                        ['link', 'clean'],
                      ]}
                      placeholder={labels.contentVn}
                      label={labels.contentVn}
                      {...form.getInputProps('contentVn')}
                    />
                  </Input.Wrapper>
                </td>
                <td>
                  <Input.Wrapper id="contentEn" label={labels.contentEn}>
                    <RichTextEditor
                      sticky={true}
                      style={{
                        height: 500,
                        overflowY: 'auto',
                      }}
                      id="contentEn"
                      controls={[
                        ['bold', 'italic', 'underline'],
                        ['h1', 'h2', 'h3', 'h4'],
                        ['alignLeft', 'alignCenter', 'alignRight'],
                        ['unorderedList', 'orderedList'],
                        ['sup', 'sub'],
                        ['link', 'clean'],
                      ]}
                      placeholder={labels.contentEn}
                      label={labels.contentEn}
                      {...form.getInputProps('contentEn')}
                    />
                  </Input.Wrapper>
                </td>
              </tr>
            </tbody>
          </Table>
          <Input.Wrapper
            label={labels.image}
            description={`Số hình ảnh tối đa là ${MAX_FILE_LENGTH}`}
          >
            <Group mt={6}>
              {files.length < MAX_FILE_LENGTH && (
                <FileButton
                  onChange={(payload: any) =>
                    setFiles((prev) => [
                      ...prev,
                      ...payload.slice(0, MAX_FILE_LENGTH - prev.length),
                    ])
                  }
                  accept="image/*"
                  multiple
                >
                  {(props) => (
                    <Box
                      {...props}
                      sx={{
                        width: 100,
                        height: 100,
                        border: '3px dashed black',
                        borderRadius: 6,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <svg
                        viewBox="0 0 24 24"
                        height={48}
                        width={48}
                        aria-hidden="true"
                        focusable="false"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        className="StyledIconBase-ea9ulj-0 bWRyML"
                      >
                        <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z" />
                      </svg>
                    </Box>
                  )}
                </FileButton>
              )}
              {images.map((file: any, index: any) => (
                <LazyLoadComponent key={index}>
                  <Image
                    imageProps={
                      {
                        'data-src': file?.url,
                      } as any
                    }
                    classNames={{
                      image: 'lazyload',
                    }}
                    width={100}
                    height={100}
                    radius={6}
                    withPlaceholder
                    src={file?.url}
                  ></Image>
                </LazyLoadComponent>
              ))}
            </Group>
          </Input.Wrapper>
          <Divider mt="xs" />
          <Group position="right" mt="xs">
            <Button
              variant="default"
              onClick={() => {
                setFiles([]);
                setOpenedModalAddInfo(false);
              }}
              disabled={isLoading}
            >
              Huỷ bỏ
            </Button>
            <Button type="submit" loading={isLoading}>
              Thêm mới
            </Button>
          </Group>
        </form>
      </Modal>
      <Modal
        opened={openedModalEditInfo}
        onClose={() => {
          setOpenedModalEditInfo(false);
        }}
        size="100%"
        title="Chỉnh sửa"
        closeOnClickOutside={false}
      >
        <LoadingOverlay visible={isLoadingFile} overlayBlur={2} />
        <form
          onSubmit={form.onSubmit((values) => {
            const data = new FormData();
            if (files.length) {
              images.forEach((image: any) => {
                const base64 = image.url.split(';base64,');
                const byteCharacters = atob(base64[1]);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                data.append('file', new Blob([byteArray], { type: image.type }), image.name);
              });
              const listImages = [
                ...files.map(() => ({
                  url: '',
                  createdTime: Date.now(),
                  createdUser: 'admin',
                  modifiedTime: Date.now(),
                  modifiedUser: 'admin',
                })),
              ];
              data.append(
                'servicevo',
                new Blob([JSON.stringify({ ...values, images: listImages })], {
                  type: 'application/json',
                }),
              );
            } else {
              data.append(
                'file',
                new Blob(undefined, {
                  type: 'multipart/form-data',
                }),
              );
              data.append(
                'servicevo',
                new Blob([JSON.stringify({ ...values })], {
                  type: 'application/json',
                }),
              );
            }
            setIsLoading(true);
            putServiceDetail(data)
              .then(() => {
                setIsLoading(false);
                showNotification({
                  title: 'Thành công',
                  message: 'Chỉnh sửa thành công',
                  color: 'green',
                  autoClose: 5000,
                });
                getService();
                form.setValues({
                  nameVn: '',
                  nameEn: '',
                  contentVn: '',
                  contentEn: '',
                  isShow: true,
                  priority: listService?.length || 0,
                  createdTime: Date.now(),
                  createdUser: 'admin',
                  modifiedTime: Date.now(),
                  modifiedUser: 'admin',
                  images: [],
                });
                setFiles([]);
                setOpenedModalEditInfo(false);
              })
              .catch(() => {
                setIsLoading(false);
              });
          })}
        >
          <Table withColumnBorders>
            <thead>
              <tr>
                <th style={{ width: '50%' }}>Tiếng Việt</th>
                <th style={{ width: '50%' }}>Tiếng Anh</th>
              </tr>
            </thead>
            <tbody className={classes.body}>
              <tr>
                <td>
                  <TextInput
                    required
                    placeholder={labels.nameVn}
                    label={labels.nameVn}
                    {...form.getInputProps('nameVn')}
                  />
                </td>
                <td>
                  <TextInput
                    required
                    placeholder={labels.nameEn}
                    label={labels.nameEn}
                    {...form.getInputProps('nameEn')}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Input.Wrapper id="contentVn" label={labels.contentVn}>
                    <RichTextEditor
                      sticky={true}
                      style={{
                        height: 500,
                        overflowY: 'auto',
                      }}
                      id="contentVn"
                      controls={[
                        ['bold', 'italic', 'underline'],
                        ['h1', 'h2', 'h3', 'h4'],
                        ['alignLeft', 'alignCenter', 'alignRight'],
                        ['unorderedList', 'orderedList'],
                        ['sup', 'sub'],
                        ['link', 'clean'],
                      ]}
                      placeholder={labels.contentVn}
                      label={labels.contentVn}
                      {...form.getInputProps('contentVn')}
                    />
                  </Input.Wrapper>
                </td>
                <td>
                  <Input.Wrapper id="contentEn" label={labels.contentEn}>
                    <RichTextEditor
                      sticky={true}
                      style={{
                        height: 500,
                        overflowY: 'auto',
                      }}
                      id="contentEn"
                      controls={[
                        ['bold', 'italic', 'underline'],
                        ['h1', 'h2', 'h3', 'h4'],
                        ['alignLeft', 'alignCenter', 'alignRight'],
                        ['unorderedList', 'orderedList'],
                        ['sup', 'sub'],
                        ['link', 'clean'],
                      ]}
                      placeholder={labels.contentEn}
                      label={labels.contentEn}
                      {...form.getInputProps('contentEn')}
                    />
                  </Input.Wrapper>
                </td>
              </tr>
            </tbody>
          </Table>
          <Input.Wrapper
            label={labels.image}
            description={`Số hình ảnh tối đa là ${MAX_FILE_LENGTH}`}
          >
            <Group mt={6}>
              {files.length <= MAX_FILE_LENGTH && (
                <FileButton
                  onChange={(payload: any) =>
                    setFiles((prev) => [
                      ...prev,
                      ...payload.slice(0, MAX_FILE_LENGTH - prev.length),
                    ])
                  }
                  accept="image/*"
                  multiple
                >
                  {(props) => (
                    <Box
                      {...props}
                      sx={{
                        width: 100,
                        height: 100,
                        border: '3px dashed black',
                        borderRadius: 6,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <svg
                        viewBox="0 0 24 24"
                        height={48}
                        width={48}
                        aria-hidden="true"
                        focusable="false"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        className="StyledIconBase-ea9ulj-0 bWRyML"
                      >
                        <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z" />
                      </svg>
                    </Box>
                  )}
                </FileButton>
              )}
              {images.map((file: any, index: any) => (
                <LazyLoadComponent key={index}>
                  <Image
                    imageProps={
                      {
                        'data-src': `${configs.BASE_IMAGE_URL}${file?.url}`,
                      } as any
                    }
                    classNames={{
                      image: 'lazyload',
                    }}
                    width={100}
                    height={100}
                    radius={6}
                    withPlaceholder
                    src={files.length ? file?.url : configs.BASE_IMAGE_URL + file?.url}
                  ></Image>
                </LazyLoadComponent>
              ))}
            </Group>
          </Input.Wrapper>
          <Divider mt="xs" />
          <Group position="right" mt="xs">
            <Button
              variant="default"
              onClick={() => {
                setFiles([]);
                setOpenedModalEditInfo(false);
              }}
              disabled={isLoading}
            >
              Huỷ bỏ
            </Button>
            <Button type="submit" loading={isLoading}>
              Chỉnh sửa
            </Button>
          </Group>
        </form>
      </Modal>
    </Paper>
  );
}

export default Service;
