import { getUser, getUserDetail } from '@apis/userApi';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface userInterface {
  id: number;
  username: string;
  password: string;
  email: string;
  phone: string;
  isDeleted: boolean;
  createdTime: string;
  modifiedTime: string;
  role: string;
}

interface serviceState {
  user: userInterface[] | [];
  userDetail: userInterface;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

export const getUserApi = createAsyncThunk('user/get', () => {
  return getUser()
    .then((response) => response.data)
    .catch((error) => error.message);
});

export const getUserDetailApi = createAsyncThunk('user/getDetail', () => {
  return getUserDetail()
    .then((response) => response.data)
    .catch((error) => error.message);
});

const initialState = {
  user: [],
  userDetail: {},
  loading: 'idle',
} as serviceState;

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserApi.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(getUserApi.fulfilled, (state, action: PayloadAction<any>) => {
      const data = action.payload;
      if (Array.isArray(data)) {
        state.user = data;
        state.loading = 'succeeded';
      }
    });
    builder.addCase(getUserApi.rejected, (state, action: PayloadAction<any>) => {
      state.loading = 'failed';
      console.error('Get user failed!');
    });
    builder.addCase(getUserDetailApi.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(getUserDetailApi.fulfilled, (state, action: PayloadAction<any>) => {
      const data = action.payload;
      state.userDetail = data;
      state.loading = 'succeeded';
    });
    builder.addCase(getUserDetailApi.rejected, (state, action: PayloadAction<any>) => {
      state.loading = 'failed';
      console.error('Get user detail failed!');
    });
  },
});

// export actions
export const {} = userSlice.actions;

export default userSlice.reducer;
