import { Loader, Center } from '@mantine/core';
import {
  LazyLoadComponent as LazyLoadWrapper,
  LazyLoadComponentProps,
} from 'react-lazy-load-image-component';

const initialValues: Omit<LazyLoadComponentProps, 'children'> = {
  placeholder: (
    <Center>
      <Loader />
    </Center>
  ),
  delayTime: 1000,
  threshold: 2000,
};

function LazyLoadComponent(props: LazyLoadComponentProps) {
  const { children, ...rest } = props;
  return (
    <LazyLoadWrapper {...initialValues} {...rest}>
      {children}
    </LazyLoadWrapper>
  );
}

export default LazyLoadComponent;
