import { deleteBanner, getBanner, postBanner, putBanner } from '@apis/bannerApi';
import Banner from '@components/Banner/Banner';
import Empty from '@components/Empty/Empty';
import LazyLoadComponent from '@components/LazyLoadComponent/LazyLoadComponent';
import configs, { compressFile } from '@constants/configs';
import { bannerType } from '@constants/types';
import {
  Anchor,
  Box,
  Button,
  createStyles,
  Divider,
  FileButton,
  Group,
  Image,
  Input,
  LoadingOverlay,
  Modal,
  Paper,
  Table,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { openConfirmModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import 'lazysizes';

const useStyles = createStyles((theme) => ({
  header: {
    position: 'sticky',
    top: 0,
    zIndex: 10,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    transition: 'box-shadow 150ms ease',

    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2]
      }`,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },

  body: {
    '& tr td': {
      verticalAlign: 'top',
      borderBottom: 'none !important',
    },
    '& tr > td:nth-of-type(1)': {
      padding: '7px 10px 7px 0px !important',
    },
    '& tr td:nth-of-type(2)': {
      padding: '7px 0px 7px 10px',
    },
  },
}));

const MAX_FILE_LENGTH = 10;

const labels = {
  tagLineVn: 'Tiêu đề',
  tagLineEn: 'Title',
  descriptionVn: 'Mô tả',
  descriptionEn: 'Description',
  image: 'Ảnh',
};

function BannerTable() {
  const { classes, cx } = useStyles();
  const [files, setFiles] = useState<File[]>([]);
  const [bannerObj, setBannerObj] = useState<bannerType | null>(null);
  const [openedModalAddInfo, setOpenedModalAddInfo] = useState(false);
  const [openedModalInfo, setOpenedModalInfo] = useState({
    index: 0,
    isOpen: false,
  });

  const [openedModalEditInfo, setOpenedModalEditInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const form = useForm({
    initialValues: {
      tagLineVn: '',
      tagLineEn: '',
      descriptionVn: '',
      descriptionEn: '',
      createdTime: Date.now(),
      createdUser: 'admin',
      modifiedTime: Date.now(),
      modifiedUser: 'admin',
      images: [],
    },
  });
  const [images, setImages] = useState<any>([]);
  // const images: any = [];
  const getBannerApi = () => {
    getBanner().then((res: any) => {
      const obj = res.data;
      setBannerObj(obj);
    });
  };
  const handleFileChosen = async (file: any) => {
    await setIsLoadingFile(true);
    const fileResult = await compressFile(file);
    await setIsLoadingFile(false);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(fileResult);
    });
  };
  useEffect(() => {
    getBannerApi();
  }, []);

  useEffect(() => {
    Promise.all(
      files.map((file) =>
        handleFileChosen(file).then((res) => ({
          name: file.name,
          url: res,
          type: file.type,
        })),
      ),
    ).then((res) => {
      setImages(res);
    });
  }, [files]);
  return (
    <Paper shadow="xl" p="md">
      <Button
        disabled={!!bannerObj?.id}
        onClick={() => {
          form.setValues({
            tagLineVn: '',
            tagLineEn: '',
            descriptionVn: '',
            descriptionEn: '',
            createdTime: Date.now(),
            createdUser: 'admin',
            modifiedTime: Date.now(),
            modifiedUser: 'admin',
            images: [],
          });
          setFiles([]);
          setOpenedModalAddInfo(true);
        }}
      >
        Tạo mới
      </Button>
      <Table highlightOnHover withColumnBorders>
        <thead>
          <tr>
            <th>{labels.tagLineVn}</th>
            <th>{labels.descriptionVn}</th>
            <th style={{ width: 320 }}>Chức năng</th>
          </tr>
        </thead>
        <tbody>
          {bannerObj?.id ? (
            <tr>
              <td>
                <Anchor
                  onClick={() => {
                    setOpenedModalInfo({
                      index: 0,
                      isOpen: true,
                    });
                  }}
                >
                  {bannerObj?.tagLineVn}
                </Anchor>
              </td>
              <td>{bannerObj?.descriptionVn}</td>
              <td>
                <Group grow>
                  <Button
                    onClick={() => {
                      setOpenedModalInfo({
                        index: 0,
                        isOpen: true,
                      });
                    }}
                  >
                    Xem
                  </Button>
                  <Button
                    onClick={() => {
                      form.setValues(bannerObj as any);
                      setImages(bannerObj.images);
                      setOpenedModalEditInfo(true);
                    }}
                  >
                    Sửa
                  </Button>
                  <Button
                    onClick={() => {
                      openConfirmModal({
                        title: 'Tiếp tục',
                        children: <Text size="sm">Bạn có muốn xoá không?</Text>,
                        labels: { confirm: 'Xoá', cancel: 'Huỷ bỏ' },
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onCancel: () => {},
                        onConfirm: () => {
                          deleteBanner(bannerObj.id!).then(() => {
                            getBannerApi();
                            showNotification({
                              title: 'Thành công',
                              message: 'Xoá thành công',
                              color: 'green',
                              autoClose: 5000,
                            });
                          });
                        },
                      });
                    }}
                  >
                    Xoá
                  </Button>
                </Group>
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={100}>
                <Empty />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <Modal
        opened={openedModalInfo.isOpen}
        onClose={() => {
          setOpenedModalInfo({
            index: 0,
            isOpen: false,
          });
        }}
        size="100%"
        title="Thông tin chi tiết"
      >
        <Table>
          <tbody>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </Table>
        <Banner />
      </Modal>
      <Modal
        opened={openedModalAddInfo}
        onClose={() => {
          setOpenedModalAddInfo(false);
        }}
        size="100%"
        title="Thêm mới"
        closeOnClickOutside={false}
      >
        <LoadingOverlay visible={isLoadingFile} overlayBlur={2} />
        <form
          onSubmit={form.onSubmit((values) => {
            const data = new FormData();
            images.forEach((image: any) => {
              const base64 = image.url.split(';base64,');
              const byteCharacters = atob(base64[1]);
              const byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              data.append('file', new Blob([byteArray], { type: image.type }), image.name);
            });
            const listImages = [
              ...files.map(() => ({
                createdTime: Date.now(),
                createdUser: 'admin',
                modifiedTime: Date.now(),
                modifiedUser: 'admin',
              })),
            ];
            data.append(
              'bannervo',
              new Blob([JSON.stringify({ ...values, id: undefined, images: listImages })], {
                type: 'application/json',
              }),
            );
            setIsLoading(true);
            postBanner(data)
              .then(() => {
                setIsLoading(false);
                showNotification({
                  title: 'Thành công',
                  message: 'Thêm mới thành công',
                  color: 'green',
                  autoClose: 5000,
                });
                getBannerApi();
                setOpenedModalAddInfo(false);
                setFiles([]);
              })
              .catch(() => {
                setIsLoading(false);
              });
          })}
        >
          <Table withColumnBorders>
            <thead>
              <tr>
                <th style={{ width: '50%' }}>Tiếng Việt</th>
                <th style={{ width: '50%' }}>Tiếng Anh</th>
              </tr>
            </thead>
            <tbody className={classes.body}>
              <tr>
                <td>
                  <TextInput
                    placeholder={labels.tagLineVn}
                    label={labels.tagLineVn}
                    {...form.getInputProps('tagLineVn')}
                  />
                </td>
                <td>
                  <TextInput
                    placeholder={labels.tagLineEn}
                    label={labels.tagLineEn}
                    {...form.getInputProps('tagLineEn')}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Input.Wrapper id="contentVn" label={labels.descriptionVn}>
                    <Textarea
                      id="contentVn"
                      placeholder={labels.descriptionVn}
                      {...form.getInputProps('descriptionVn')}
                      minRows={2}
                      maxRows={4}
                    />
                  </Input.Wrapper>
                </td>
                <td>
                  <Input.Wrapper id="contentEn" label={labels.descriptionEn}>
                    <Textarea
                      id="contentEn"
                      placeholder={labels.descriptionEn}
                      {...form.getInputProps('descriptionEn')}
                      minRows={2}
                      maxRows={4}
                    />
                  </Input.Wrapper>
                </td>
              </tr>
            </tbody>
          </Table>
          <Input.Wrapper
            label={labels.image}
            description={`Số hình ảnh tối đa là ${MAX_FILE_LENGTH}`}
          >
            <Group mt={6}>
              {files.length < MAX_FILE_LENGTH && (
                <FileButton
                  onChange={(payload: any) =>
                    setFiles((prev) => [
                      ...prev,
                      ...payload.slice(0, MAX_FILE_LENGTH - prev.length),
                    ])
                  }
                  accept="image/*"
                  multiple
                >
                  {(props) => (
                    <Box
                      {...props}
                      sx={{
                        width: 100,
                        height: 100,
                        border: '3px dashed black',
                        borderRadius: 6,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <svg
                        viewBox="0 0 24 24"
                        height={48}
                        width={48}
                        aria-hidden="true"
                        focusable="false"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        className="StyledIconBase-ea9ulj-0 bWRyML"
                      >
                        <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z" />
                      </svg>
                    </Box>
                  )}
                </FileButton>
              )}
              {images.map((file: any, index: any) => (
                <LazyLoadComponent key={index}>
                  <Image
                    imageProps={
                      {
                        'data-src': files.length ? file?.url : configs.BASE_IMAGE_URL + file?.url,
                      } as any
                    }
                    classNames={{
                      image: 'lazyload',
                    }}
                    width={100}
                    height={100}
                    radius={6}
                    withPlaceholder
                    src={files.length ? file?.url : configs.BASE_IMAGE_URL + file?.url}
                  ></Image>
                </LazyLoadComponent>
              ))}
            </Group>
          </Input.Wrapper>
          <Divider mt="xs" />
          <Group position="right" mt="xs">
            <Button
              variant="default"
              onClick={() => {
                setOpenedModalAddInfo(false);
              }}
              disabled={isLoading}
            >
              Huỷ bỏ
            </Button>
            <Button type="submit" loading={isLoading}>
              Thêm mới
            </Button>
          </Group>
        </form>
      </Modal>
      <Modal
        opened={openedModalEditInfo}
        onClose={() => {
          setOpenedModalEditInfo(false);
        }}
        size="100%"
        title="Chỉnh sửa"
        closeOnClickOutside={false}
      >
        <LoadingOverlay visible={isLoadingFile} overlayBlur={2} />
        <form
          onSubmit={form.onSubmit((values) => {
            const data = new FormData();
            if (files.length) {
              images.forEach((image: any) => {
                const base64 = image.url.split(';base64,');
                const byteCharacters = atob(base64[1]);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                data.append('file', new Blob([byteArray], { type: image.type }), image.name);
              });
              const listImages = [
                ...files.map(() => ({
                  url: '',
                  createdTime: Date.now(),
                  createdUser: 'admin',
                  modifiedTime: Date.now(),
                  modifiedUser: 'admin',
                })),
              ];
              data.append(
                'bannervo',
                new Blob([JSON.stringify({ ...values, images: listImages })], {
                  type: 'application/json',
                }),
              );
            } else {
              data.append(
                'file',
                new Blob(undefined, {
                  type: 'multipart/form-data',
                }),
              );
              data.append(
                'bannervo',
                new Blob([JSON.stringify({ ...values })], {
                  type: 'application/json',
                }),
              );
            }
            setIsLoading(true);
            putBanner(data)
              .then(() => {
                setIsLoading(false);
                showNotification({
                  title: 'Thành công',
                  message: 'Chỉnh sửa thành công',
                  color: 'green',
                  autoClose: 5000,
                });
                getBannerApi();
                form.setValues({
                  tagLineVn: '',
                  tagLineEn: '',
                  descriptionVn: '',
                  descriptionEn: '',
                  createdTime: Date.now(),
                  createdUser: 'admin',
                  modifiedTime: Date.now(),
                  modifiedUser: 'admin',
                  images: [],
                });
                setFiles([]);
                setOpenedModalEditInfo(false);
              })
              .catch(() => {
                setIsLoading(false);
              });
          })}
        >
          <Table withColumnBorders>
            <thead>
              <tr>
                <th style={{ width: '50%' }}>Tiếng Việt</th>
                <th style={{ width: '50%' }}>Tiếng Anh</th>
              </tr>
            </thead>
            <tbody className={classes.body}>
              <tr>
                <td>
                  <TextInput
                    placeholder={labels.tagLineVn}
                    label={labels.tagLineVn}
                    {...form.getInputProps('tagLineVn')}
                  />
                </td>
                <td>
                  <TextInput
                    placeholder={labels.tagLineEn}
                    label={labels.tagLineEn}
                    {...form.getInputProps('tagLineEn')}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Input.Wrapper id="contentVn" label={labels.descriptionVn}>
                    <Textarea
                      id="contentVn"
                      placeholder={labels.descriptionVn}
                      {...form.getInputProps('descriptionVn')}
                      minRows={2}
                      maxRows={4}
                    />
                  </Input.Wrapper>
                </td>
                <td>
                  <Input.Wrapper id="contentEn" label={labels.descriptionEn}>
                    <Textarea
                      id="contentEn"
                      placeholder={labels.descriptionEn}
                      {...form.getInputProps('descriptionEn')}
                      minRows={2}
                      maxRows={4}
                    />
                  </Input.Wrapper>
                </td>
              </tr>
            </tbody>
          </Table>
          <Input.Wrapper
            label={labels.image}
            description={`Số hình ảnh tối đa là ${MAX_FILE_LENGTH}`}
          >
            <Group mt={6}>
              {files.length < MAX_FILE_LENGTH && (
                <FileButton
                  onChange={(payload: any) =>
                    setFiles((prev) => [
                      ...prev,
                      ...payload.slice(0, MAX_FILE_LENGTH - prev.length),
                    ])
                  }
                  accept="image/*"
                  multiple
                >
                  {(props) => (
                    <Box
                      {...props}
                      sx={{
                        width: 100,
                        height: 100,
                        border: '3px dashed black',
                        borderRadius: 6,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <svg
                        viewBox="0 0 24 24"
                        height={48}
                        width={48}
                        aria-hidden="true"
                        focusable="false"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        className="StyledIconBase-ea9ulj-0 bWRyML"
                      >
                        <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z" />
                      </svg>
                    </Box>
                  )}
                </FileButton>
              )}
              {images.map((file: any, index: any) => (
                <LazyLoadComponent key={index}>
                  <Image
                    imageProps={
                      {
                        'data-src': files.length ? file?.url : configs.BASE_IMAGE_URL + file?.url,
                      } as any
                    }
                    classNames={{
                      image: 'lazyload',
                    }}
                    width={100}
                    height={100}
                    radius={6}
                    withPlaceholder
                    src={files.length ? file?.url : configs.BASE_IMAGE_URL + file?.url}
                  ></Image>
                </LazyLoadComponent>
              ))}
            </Group>
          </Input.Wrapper>
          <Divider mt="xs" />
          <Group position="right" mt="xs">
            <Button
              variant="default"
              onClick={() => {
                setOpenedModalEditInfo(false);
                setFiles([]);
              }}
              disabled={isLoading}
            >
              Huỷ bỏ
            </Button>
            <Button type="submit" loading={isLoading}>
              Chỉnh sửa
            </Button>
          </Group>
        </form>
      </Modal>
    </Paper>
  );
}

export default BannerTable;
