import { getProjectTypes } from '@apis/projectApi';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface projectTypeInterface {
  id: number;
  nameVn: string;
  nameEn: string;
  createdTime?: string;
  createdUser?: string;
  modifiedTime?: string;
  modifiedUser?: string;
  isDeleted?: boolean;
}

export const getProjectTypesApi = createAsyncThunk('projectTypes/get', () => {
  return getProjectTypes()
    .then((response) => response.data)
    .catch((error) => error.message);
});

interface projectTypeState {
  projectType: projectTypeInterface[] | [];
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState = {
  projectType: [],
  loading: 'idle',
} as projectTypeState;

const projectTypeSlice = createSlice({
  name: 'projectType',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProjectTypesApi.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(getProjectTypesApi.fulfilled, (state, action: PayloadAction<any>) => {
      const data = action.payload;
      if (Array.isArray(data)) {
        state.projectType = data;
        state.loading = 'succeeded';
      }
    });
    builder.addCase(getProjectTypesApi.rejected, (state, action: PayloadAction<any>) => {
      state.loading = 'failed';
      console.error('Get project failed!');
    });
  },
});

// export actions
export const {} = projectTypeSlice.actions;

export default projectTypeSlice.reducer;
