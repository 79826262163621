import { Flex, Text } from '@mantine/core';
import React from 'react';

function Empty() {
  return (
    <Flex gap="md" justify="center" align="center" direction="column" wrap="wrap">
      <svg
        width={56}
        fill="none"
        stroke="#868e96"
        strokeWidth={4}
        viewBox="0 0 48 48"
        aria-hidden="true"
        focusable="false"
        className="arco-icon arco-icon-empty"
      >
        <path d="M24 5v6m7 1 4-4m-18 4-4-4m28.5 22H28s-1 3-4 3-4-3-4-3H6.5M40 41H8a2 2 0 0 1-2-2v-8.46a2 2 0 0 1 .272-1.007l6.15-10.54A2 2 0 0 1 14.148 18H33.85a2 2 0 0 1 1.728.992l6.149 10.541A2 2 0 0 1 42 30.541V39a2 2 0 0 1-2 2Z" />
      </svg>
      <Text fw={500} color="dimmed">
        Không có dữ liệu
      </Text>
    </Flex>
  );
}

export default Empty;
