import { projectTypeInterface } from './projectType.slice';
import { getProjects, getProjectTypes } from '@apis/projectApi';
import { imageType } from '@constants/types';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { serviceInterface } from './service.slice';

export interface projectInterface {
  id: number;
  status: 'in progress' | 'completed' | 'failed';
  nameVn: string; //Name of the project bỏ phần title
  nameEn: string; //Name of the project bỏ phần title
  addressVn: string; //trường này trong db còn thiếu
  addressEn: string; //trường này trong db còn thiếu
  contentVn: string;
  contentEn: string;
  area: number; //VD: 30000 m
  duration: number;
  investorVn: string;
  investorEn: string;
  start: string; //startTime
  mainContractorVn: string;
  mainContractorEn: string;
  projectType: projectTypeInterface;
  priority?: number;
  isShow?: boolean;
  services: serviceInterface[]; //Mảng id service của project
  images: imageType[];
  createdTime?: string;
  createdUser?: string;
  modifiedTime?: string;
  modifiedUser?: string;
}

export const getProjectApi = createAsyncThunk('projects/get', () => {
  return getProjects()
    .then((response) => response.data)
    .catch((error) => error.message);
});

export const getProjectTypesApi = createAsyncThunk('projectTypes/get', () => {
  return getProjectTypes()
    .then((response) => response.data)
    .catch((error) => error.message);
});

interface projectState {
  project: projectInterface[] | [];
  projectTypes: projectTypeInterface[] | [];
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState = {
  project: [],
  projectTypes: [],
  loading: 'idle',
} as projectState;

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProjectApi.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(getProjectApi.fulfilled, (state, action: PayloadAction<any>) => {
      const data = action.payload;
      if (Array.isArray(data)) {
        state.project = data.map((project) => ({
          ...project,
          status: project.status === 0 ? 'in progress' : 'completed',
        }));
        state.loading = 'succeeded';
      }
    });
    builder.addCase(getProjectApi.rejected, (state, action: PayloadAction<any>) => {
      state.loading = 'failed';
      console.error('Get project failed!');
    });
    builder.addCase(getProjectTypesApi.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(getProjectTypesApi.fulfilled, (state, action: PayloadAction<any>) => {
      const data = action.payload;
      if (Array.isArray(data)) {
        state.projectTypes = data;
        state.loading = 'succeeded';
      }
    });
    builder.addCase(getProjectTypesApi.rejected, (state) => {
      state.loading = 'failed';
      console.error('Get project type failed!');
    });
  },
});

// export actions
export const {} = projectSlice.actions;

export default projectSlice.reducer;
