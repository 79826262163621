import Logo from '@assets/favicon/logo.png';
import {
  Accordion,
  Burger,
  createStyles,
  Divider,
  HoverCard,
  Paper,
  Text,
  Transition,
} from '@mantine/core';
import { useMediaQuery, useScrollLock, useShallowEffect } from '@mantine/hooks';
import { useScrollDirection } from 'hooks/useScrollDirection';
import i18next from 'i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hook';
import { aboutInterface, getAboutApi } from 'redux/reducer/about.slice';
import { getProjectApi, projectInterface } from 'redux/reducer/projects.slice';
import { getRecruitApi, recruitInterface } from 'redux/reducer/recruit.slice';
import { getServiceApi, serviceInterface } from 'redux/reducer/service.slice';
import './header.scss';

type MainNavType = {
  display: string;
  path: string;
  hoverComponent?: React.FC;
  list?: any[];
};

const useStyles = createStyles((theme) => ({
  dropdown: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'absolute',
    top: 50,
    left: 0,
    right: 0,
    bottom: 0,
    height: 'calc(100vh - 50px)',
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',

    [theme.fn.largerThan(1025)]: {
      display: 'none',
    },
    [theme.fn.largerThan(600)]: {
      top: 90,
    },
  },
}));

const Header = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [, setScrollLocked] = useScrollLock();
  const [opened, setOpened] = useState(false);
  const mobileMatches = useMediaQuery('(max-width: 1024px)');
  const history = useNavigate();
  const aboutList = [...useAppSelector((state) => state.about.about)]
    .sort(
      (a: aboutInterface, b: aboutInterface) =>
        (b?.createdTime?.localeCompare(a?.createdTime ?? '') && a?.id - b?.id) ?? 1,
    )
    .reverse();
  const serviceList = [...useAppSelector((state) => state.service.service)]
    .sort(
      (a: serviceInterface, b: serviceInterface) =>
        (b?.createdTime?.localeCompare(a?.createdTime ?? '') && a?.id - b?.id) ?? 1,
    )
    .reverse();
  const projectList = [...useAppSelector((state) => state.projects.project)]
    .sort(
      (a: projectInterface, b: projectInterface) =>
        (b?.createdTime?.localeCompare(a?.createdTime ?? '') && a?.id - b?.id) ?? 1,
    )
    .reverse();
  const recruitList = [...useAppSelector((state) => state.recruit.recruit)]
    .sort(
      (a: recruitInterface, b: recruitInterface) =>
        (b?.createdTime?.localeCompare(a?.createdTime ?? '') && a?.id - b?.id) ?? 1,
    )
    .reverse();
  // const contactList = useAppSelector((state) => state.contact.contact);
  // const [y, setY] = useState(window.scrollY);
  const { isScrollingUp, isScrollingDown } = useScrollDirection();
  const headerRef = useRef<any>();
  const dispatch = useAppDispatch();

  // const handleNavigation = useCallback(
  //   (e: any) => {
  //     const window = e.currentTarget;
  //     if (y - window?.scrollY > 200) {
  //       headerRef.current.classList?.remove('scroll-bot');
  //       headerRef.current.classList?.add('scroll-top');
  //       setY(window?.scrollY);
  //     } else if (y - window?.scrollY < -200) {
  //       headerRef.current.classList?.remove('scroll-top');
  //       headerRef.current.classList?.add('scroll-bot');
  //       setY(window?.scrollY);
  //     }
  //   },
  //   [y],
  // );

  // useShallowEffect(() => {
  //   setY(window.scrollY);
  //   window.addEventListener('scroll', handleNavigation);

  //   return () => {
  //     window.removeEventListener('scroll', handleNavigation);
  //   };
  // }, [handleNavigation]);

  useShallowEffect(() => {
    if (!mobileMatches) {
      setOpened(false);
      setScrollLocked(false);
      headerRef.current.classList?.remove('scroll-bot');
      headerRef.current.classList?.remove('scroll-top');
    }
  }, [mobileMatches]);

  useShallowEffect(() => {
    if (isScrollingUp) {
      headerRef.current.classList?.remove('scroll-bot');
      headerRef.current.classList?.add('scroll-top');
    } else if (isScrollingDown) {
      headerRef.current.classList?.add('scroll-bot');
      headerRef.current.classList?.remove('scroll-top');
    }
  }, [isScrollingUp, isScrollingDown]);

  useEffect(() => {
    dispatch(getRecruitApi());
    dispatch(getAboutApi());
    dispatch(getServiceApi());
    dispatch(getProjectApi());
    document.documentElement.lang = i18next.language === 'vi_VN' ? 'vi' : 'en';
  }, [i18next.language]);

  const mainNav: MainNavType[] = [
    {
      display: 'About',
      path: '/about',
      list: aboutList?.filter((about) => about.isShow),
    },
    {
      display: 'Service',
      path: '/service',
      list: serviceList?.filter((service) => service.isShow),
    },
    {
      display: 'Project',
      path: '/projects',
      list: projectList?.filter((project) => project.isShow),
    },
    {
      display: 'Contact',
      path: '/contact',
      // list: [...contactList].map((contact) => ({
      //   nameVn: contact.name,
      //   nameEn: contact.name,
      //   to: `#${contact.id}`,
      //   path: '',
      // })),
    },
    {
      display: 'Recruit',
      path: '/recruitment',
      list: recruitList?.filter((recruitment) => recruitment.isShow),
    },
  ];
  return (
    <div
      className="header wrap"
      ref={headerRef}
      style={{
        ...(opened && { position: 'fixed', top: 0 }),
      }}
    >
      <Link to="/">
        <img src={Logo} alt="Logo" className="logo" />
      </Link>
      <div className="nav-header">
        {!!mainNav?.length &&
          mainNav.map((nav, index) => (
            <HoverCard key={index} shadow="md" offset={56} radius={20}>
              <HoverCard.Target>
                <NavLink key={nav.display} to={nav.path} className="nav-header__item">
                  <span>{t(nav.display)}</span>
                </NavLink>
              </HoverCard.Target>
              <HoverCard.Dropdown p={48}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      marginRight: 73,
                    }}
                  >
                    <Text fw={700} fz={40} transform="uppercase">
                      {t(nav.display)}
                    </Text>
                    <Link key={nav.display} to={nav.path} className="nav-header__item">
                      <span>{t('More')}</span>
                    </Link>
                  </div>
                  {!!nav.list && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        borderLeft: '3px solid #CCCCCC',
                        paddingLeft: 24,
                        gap: 10,
                      }}
                    >
                      {nav.list.slice(0, 5).map((item, index) => (
                        <Link
                          key={index}
                          to={
                            nav.path +
                            `${item?.to ?? ''}${
                              (item.id || item.path) && `/${item.id ?? item.path}`
                            }`
                          }
                        >
                          {(i18next.language === 'vi_VN' ? item.nameVn : item.nameEn) ||
                            (i18next.language === 'vi_VN' ? item.roleVn : item.roleEn)}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              </HoverCard.Dropdown>
            </HoverCard>
          ))}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            className={`nav-header__item lang ${
              i18next.language === 'vi_VN' ? 'active' : ''
            }`.trim()}
            onClick={() => i18next.changeLanguage('vi_VN')}
          >
            Tiếng việt
          </div>
          <Divider
            orientation="vertical"
            size="sm"
            style={{
              height: 24,
              margin: 'auto 0',
            }}
          />
          <div
            className={`nav-header__item lang ${
              i18next.language === 'en_US' ? 'active' : ''
            }`.trim()}
            onClick={() => i18next.changeLanguage('en_US')}
          >
            English
          </div>
        </div>
      </div>
      <div className="nav-header--mobile">
        <Burger
          color="#0072B8"
          opened={opened}
          onClick={() => {
            setOpened(!opened);
            setScrollLocked(!opened);
          }}
        />
      </div>
      <Transition transition="fade" duration={200} mounted={opened}>
        {() => (
          <Paper className={classes.dropdown} radius={0}>
            <Accordion
              variant="filled"
              styles={() => ({
                item: {
                  background: 'inherit',
                },
                label: {
                  fontWeight: 500,
                  font: 'Helvetica Neue',
                  fontSize: 26,
                  color: '#0072B8',
                  textTransform: 'uppercase',
                },
                content: {
                  fontWeight: 500,
                },
              })}
            >
              {!!mainNav?.length &&
                mainNav.map((nav, index) => (
                  <Accordion.Item
                    key={index}
                    value={nav.display}
                    onClick={() => {
                      if (nav.display === 'Contact') {
                        setOpened(!opened);
                        setScrollLocked(!opened);
                        history('/contact');
                      }
                    }}
                  >
                    <Accordion.Control
                      chevron={
                        nav?.list?.slice(0, 5).length ? (
                          <svg
                            width={18}
                            height={9}
                            viewBox="0 0 18 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.666626 0.666992L8.99996 9.00033L17.3333 0.666992H0.666626Z"
                              fill="#0072B8"
                            />
                          </svg>
                        ) : (
                          ' '
                        )
                      }
                    >
                      {nav.display === 'Contact' ? (
                        <NavLink
                          to={nav.path}
                          onClick={() => {
                            setOpened(!opened);
                            setScrollLocked(!opened);
                          }}
                        >
                          <span>{t(nav.display)}</span>
                        </NavLink>
                      ) : (
                        <span>{t(nav.display)}</span>
                      )}
                    </Accordion.Control>
                    <Accordion.Panel>
                      {!!nav?.list?.slice(0, 5).length && (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            paddingLeft: 24,
                            gap: 10,
                          }}
                        >
                          {nav.list.slice(0, 5).map((item, index) => (
                            <Link
                              key={index}
                              to={
                                nav.path +
                                `${item?.to ?? ''}${
                                  (item.id || item.path) && `/${item.id ?? item.path}`
                                }`
                              }
                              onClick={() => {
                                setOpened(!opened);
                                setScrollLocked(!opened);
                              }}
                            >
                              {(i18next.language === 'vi_VN' ? item.nameVn : item.nameEn) ||
                                (i18next.language === 'vi_VN' ? item.roleVn : item.roleEn)}
                            </Link>
                          ))}
                          <Link
                            key={nav.display}
                            to={nav.path}
                            onClick={() => {
                              setOpened(!opened);
                              setScrollLocked(!opened);
                            }}
                          >
                            <span>{t('More')}...</span>
                          </Link>
                        </div>
                      )}
                    </Accordion.Panel>
                  </Accordion.Item>
                ))}
            </Accordion>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: 12,
                alignItems: 'center',
                marginBottom: 72,
              }}
            >
              <div
                className={`nav-header__item lang ${
                  i18next.language === 'vi_VN' ? 'active' : ''
                }`.trim()}
                onClick={() => i18next.changeLanguage('vi_VN')}
              >
                Tiếng việt
              </div>
              <Divider
                orientation="vertical"
                size="sm"
                style={{
                  height: 24,
                  margin: 'auto 0',
                }}
              />
              <div
                className={`nav-header__item lang ${
                  i18next.language === 'en_US' ? 'active' : ''
                }`.trim()}
                onClick={() => i18next.changeLanguage('en_US')}
              >
                English
              </div>
            </div>
          </Paper>
        )}
      </Transition>
    </div>
  );
};

export default Header;
