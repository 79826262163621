import axiosInstance from './axiosInstance';

const token = localStorage.getItem('token');

export const getUser = () =>
  axiosInstance.get('/admin/user', {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Basic ${token}`,
    },
  });

export const deleteUserDetail = (id: number) =>
  axiosInstance.delete(`/admin/user?userId=${id}`, undefined, {
    headers: { Authorization: `Basic ${token}` },
  });

export const getUserDetail = () => {
  return axiosInstance.get('/admin/user/userDetail', {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Basic ${token}`,
    },
  });
};

export const postSignup = (payload: any) => {
  return axiosInstance.post('/api/signup', payload);
};
