import { deleteRecruitDetail, postRecruitDetail, putRecruitDetail } from '@apis/recruitApi';
import Empty from '@components/Empty/Empty';
import {
  ActionIcon,
  Anchor,
  Button,
  createStyles,
  Divider,
  Group,
  Input,
  Modal,
  NumberInput,
  Paper,
  ScrollArea,
  Switch,
  Table,
  Text,
  TextInput,
  TypographyStylesProvider,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useElementSize, useViewportSize } from '@mantine/hooks';
import { openConfirmModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import RichTextEditor from '@mantine/rte';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hook';
import { getRecruitApi } from 'redux/reducer/recruit.slice';

const useStyles = createStyles((theme) => ({
  header: {
    position: 'sticky',
    top: 0,
    zIndex: 10,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    transition: 'box-shadow 150ms ease',

    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2]
      }`,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },

  body: {
    '& tr td': {
      verticalAlign: 'top',
      borderBottom: 'none !important',
    },
    '& tr > td:nth-of-type(1)': {
      padding: '7px 10px 7px 0px !important',
    },
    '& tr td:nth-of-type(2)': {
      padding: '7px 0px 7px 10px',
    },
  },
}));

const labels = {
  roleVn: 'Tiêu đề',
  roleEn: 'Title',
  addressVn: 'Địa chỉ',
  addressEn: 'Address',
  contentVn: 'Mô tả',
  contentEn: 'Description',
  salaryVn: 'Lương',
  salaryEn: 'Salary',
};

function Recruit() {
  const { classes, cx } = useStyles();
  const dispatch = useAppDispatch();
  const listRecruit = useAppSelector((state) => state.recruit.recruit);
  const [openedModalAddInfo, setOpenedModalAddInfo] = useState(false);
  const [openedModalInfo, setOpenedModalInfo] = useState({
    index: 0,
    isOpen: false,
  });
  const [scrolled, setScrolled] = useState(false);
  const { height } = useViewportSize();
  const { ref, height: elementHeight } = useElementSize();
  const [openedModalEditInfo, setOpenedModalEditInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm({
    initialValues: {
      roleVn: '',
      roleEn: '',
      addressVn: '',
      addressEn: '',
      contentVn: '',
      contentEn: '',
      salary: '',
      isShow: true,
      priority: listRecruit?.length || 0,
      createdTime: Date.now(),
      createdUser: 'admin',
      modifiedTime: Date.now(),
      modifiedUser: 'admin',
    },
  });
  const getRecruit = () => {
    dispatch(getRecruitApi());
  };
  useEffect(() => {
    getRecruit();
  }, []);

  useEffect(() => {
    form.setValues({ priority: listRecruit.length });
  }, [listRecruit]);
  return (
    <Paper shadow="xl" p="md">
      <Button
        onClick={() => {
          setOpenedModalAddInfo(true);
        }}
      >
        Tạo mới
      </Button>
      <ScrollArea
        sx={{ height: elementHeight >= 0.72 * height ? '72vh' : 'auto' }}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      >
        <Table ref={ref} highlightOnHover withColumnBorders>
          <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
            <tr>
              <th style={{ width: 40 }}>Hiển thị</th>
              <th>{labels.roleVn}</th>
              <th>{labels.addressVn}</th>
              <th>{labels.salaryVn}</th>
              <th style={{ width: 100 }}>Độ ưu tiên</th>
              <th style={{ width: 320 }}>Chức năng</th>
            </tr>
          </thead>
          <tbody>
            {listRecruit?.length ? (
              listRecruit.map((recruit, index, arr) => (
                <tr key={recruit.id}>
                  <td>
                    <Switch
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      checked={recruit.isShow}
                      onClick={(event: any) => {
                        putRecruitDetail({ ...recruit, isShow: event.target.checked }).then(() => {
                          getRecruit();
                        });
                      }}
                    />
                  </td>
                  <td>
                    <Anchor
                      onClick={() => {
                        setOpenedModalInfo({
                          index,
                          isOpen: true,
                        });
                      }}
                    >
                      {recruit.roleVn}
                    </Anchor>
                  </td>
                  <td>{recruit.addressVn}</td>
                  <td>{recruit.salary}</td>
                  <td>
                    <Group>
                      <ActionIcon
                        onClick={() => {
                          putRecruitDetail({
                            ...recruit,
                            priority: index > 1 ? arr.at(index - 1)!.priority! - 1 : 0,
                          }).then(() => {
                            getRecruit();
                          });
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-arrow-up"
                          width={44}
                          height={44}
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="#2c3e50"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <line x1={12} y1={5} x2={12} y2={19} />
                          <line x1={18} y1={11} x2={12} y2={5} />
                          <line x1={6} y1={11} x2={12} y2={5} />
                        </svg>
                      </ActionIcon>
                      <ActionIcon
                        onClick={() => {
                          putRecruitDetail({
                            ...recruit,
                            priority:
                              index < arr.length ? arr.at(index + 1)!.priority! + 1 : arr.length,
                          }).then(() => {
                            getRecruit();
                          });
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-arrow-down"
                          width={44}
                          height={44}
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="#2c3e50"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <line x1={12} y1={5} x2={12} y2={19} />
                          <line x1={18} y1={13} x2={12} y2={19} />
                          <line x1={6} y1={13} x2={12} y2={19} />
                        </svg>
                      </ActionIcon>
                    </Group>
                  </td>
                  <td>
                    <Group grow>
                      <Button
                        onClick={() => {
                          setOpenedModalInfo({
                            index,
                            isOpen: true,
                          });
                        }}
                      >
                        Xem
                      </Button>
                      <Button
                        onClick={() => {
                          form.setValues(recruit as any);
                          setOpenedModalEditInfo(true);
                        }}
                      >
                        Sửa
                      </Button>
                      <Button
                        onClick={() => {
                          openConfirmModal({
                            title: 'Tiếp tục',
                            children: <Text size="sm">Bạn có muốn xoá không?</Text>,
                            labels: { confirm: 'Xoá', cancel: 'Huỷ bỏ' },
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onCancel: () => {},
                            onConfirm: () => {
                              deleteRecruitDetail(recruit.id).then(() => {
                                getRecruit();
                                showNotification({
                                  title: 'Thành công',
                                  message: 'Xoá thành công',
                                  color: 'green',
                                  autoClose: 5000,
                                });
                              });
                            },
                          });
                        }}
                      >
                        Xoá
                      </Button>
                    </Group>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={100}>
                  <Empty />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </ScrollArea>
      <Modal
        opened={openedModalInfo.isOpen}
        onClose={() => {
          setOpenedModalInfo({
            index: 0,
            isOpen: false,
          });
        }}
        size="100%"
        title="Thông tin chi tiết"
      >
        <Table>
          <tbody>
            <tr>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </Table>
        <TypographyStylesProvider>
          <div
            dangerouslySetInnerHTML={{
              __html: listRecruit?.at(openedModalInfo.index)?.contentVn || '',
            }}
          />
        </TypographyStylesProvider>
      </Modal>
      <Modal
        opened={openedModalAddInfo}
        onClose={() => {
          setOpenedModalAddInfo(false);
        }}
        size="100%"
        title="Thêm mới"
        closeOnClickOutside={false}
      >
        <form
          onSubmit={form.onSubmit((values) => {
            setIsLoading(true);
            postRecruitDetail({
              ...values,
              id: undefined,
              roleEn: values.roleVn,
              addressEn: values.addressVn,
              contentEn: values.contentVn,
            })
              .then(() => {
                setIsLoading(false);
                showNotification({
                  title: 'Thành công',
                  message: 'Thêm mới thành công',
                  color: 'green',
                  autoClose: 5000,
                });
                getRecruit();
                form.setValues({
                  roleVn: '',
                  roleEn: '',
                  addressVn: '',
                  addressEn: '',
                  contentVn: '',
                  contentEn: '',
                  salary: '',
                  isShow: true,
                  priority: listRecruit?.length || 0,
                  createdTime: Date.now(),
                  createdUser: 'admin',
                  modifiedTime: Date.now(),
                  modifiedUser: 'admin',
                });
                setOpenedModalAddInfo(false);
              })
              .catch(() => {
                setIsLoading(false);
              });
          })}
        >
          <TextInput
            required
            placeholder={labels.roleVn}
            label={labels.roleVn}
            {...form.getInputProps('roleVn')}
          />
          <TextInput
            placeholder={labels.addressVn}
            label={labels.addressVn}
            {...form.getInputProps('addressVn')}
          />
          <Input.Wrapper id="contentVn" label={labels.contentVn}>
            <RichTextEditor
              sticky={true}
              style={{
                height: 500,
                overflowY: 'auto',
              }}
              id="contentVn"
              controls={[
                ['bold', 'italic', 'underline'],
                ['h1', 'h2', 'h3', 'h4'],
                ['alignLeft', 'alignCenter', 'alignRight'],
                ['unorderedList', 'orderedList'],
                ['sup', 'sub'],
                ['link', 'clean'],
              ]}
              placeholder={labels.contentVn}
              label={labels.contentVn}
              {...form.getInputProps('contentVn')}
            />
          </Input.Wrapper>
          <NumberInput
            placeholder={labels.salaryVn}
            label={labels.salaryVn}
            hideControls
            parser={(value: any) => value.replace(/(,*)/g, '')}
            formatter={(value: any) =>
              !Number.isNaN(parseFloat(value))
                ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                : ''
            }
            {...form.getInputProps('salary')}
          />
          <Divider mt="xs" />
          <Group position="right" mt="xs">
            <Button
              variant="default"
              onClick={() => {
                form.setValues({
                  roleVn: '',
                  roleEn: '',
                  addressVn: '',
                  addressEn: '',
                  contentVn: '',
                  contentEn: '',
                  salary: '',
                  isShow: true,
                  priority: listRecruit?.length || 0,
                  createdTime: Date.now(),
                  createdUser: 'admin',
                  modifiedTime: Date.now(),
                  modifiedUser: 'admin',
                });
                setOpenedModalAddInfo(false);
              }}
              disabled={isLoading}
            >
              Huỷ bỏ
            </Button>
            <Button type="submit" loading={isLoading}>
              Thêm mới
            </Button>
          </Group>
        </form>
      </Modal>
      <Modal
        opened={openedModalEditInfo}
        onClose={() => {
          setOpenedModalEditInfo(false);
        }}
        size="100%"
        title="Chỉnh sửa"
        closeOnClickOutside={false}
      >
        <form
          onSubmit={form.onSubmit((values) => {
            setIsLoading(true);
            putRecruitDetail({
              ...values,
              roleEn: values.roleVn,
              addressEn: values.addressVn,
              contentEn: values.contentVn,
            })
              .then(() => {
                setIsLoading(false);
                showNotification({
                  title: 'Thành công',
                  message: 'Chỉnh sửa thành công',
                  color: 'green',
                  autoClose: 5000,
                });
                getRecruit();
                form.setValues({
                  roleVn: '',
                  roleEn: '',
                  addressVn: '',
                  addressEn: '',
                  contentVn: '',
                  contentEn: '',
                  salary: '',
                  isShow: true,
                  priority: listRecruit?.length || 0,
                  createdTime: Date.now(),
                  createdUser: 'admin',
                  modifiedTime: Date.now(),
                  modifiedUser: 'admin',
                });
                setOpenedModalEditInfo(false);
              })
              .catch(() => {
                setIsLoading(false);
              });
          })}
        >
          <TextInput
            required
            placeholder={labels.roleVn}
            label={labels.roleVn}
            {...form.getInputProps('roleVn')}
          />
          <TextInput
            placeholder={labels.addressVn}
            label={labels.addressVn}
            {...form.getInputProps('addressVn')}
          />
          <Input.Wrapper id="contentVn" label={labels.contentVn}>
            <RichTextEditor
              sticky={true}
              style={{
                height: 500,
                overflowY: 'auto',
              }}
              id="contentVn"
              controls={[
                ['bold', 'italic', 'underline'],
                ['h1', 'h2', 'h3', 'h4'],
                ['alignLeft', 'alignCenter', 'alignRight'],
                ['unorderedList', 'orderedList'],
                ['sup', 'sub'],
                ['link', 'clean'],
              ]}
              placeholder={labels.contentVn}
              label={labels.contentVn}
              {...form.getInputProps('contentVn')}
            />
          </Input.Wrapper>
          <NumberInput
            placeholder={labels.salaryVn}
            label={labels.salaryVn}
            hideControls
            parser={(value) => value?.replace(/(,*)/g, '')}
            formatter={(value: any) =>
              !Number.isNaN(parseFloat(value))
                ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                : ''
            }
            {...form.getInputProps('salary')}
          />
          <Divider mt="xs" />
          <Group position="right" mt="xs">
            <Button
              variant="default"
              onClick={() => {
                form.setValues({
                  roleVn: '',
                  roleEn: '',
                  addressVn: '',
                  addressEn: '',
                  contentVn: '',
                  contentEn: '',
                  salary: '',
                  isShow: true,
                  priority: listRecruit?.length || 0,
                  createdTime: Date.now(),
                  createdUser: 'admin',
                  modifiedTime: Date.now(),
                  modifiedUser: 'admin',
                });
                setOpenedModalEditInfo(false);
              }}
              disabled={isLoading}
            >
              Huỷ bỏ
            </Button>
            <Button type="submit" loading={isLoading}>
              Chỉnh sửa
            </Button>
          </Group>
        </form>
      </Modal>
    </Paper>
  );
}

export default Recruit;
